import { deepPurple } from '@material-ui/core/colors';
let palette = {
  colors: {
    active: '#debd1d',
    mainText: '#242424',
    secondaryText: '#808080',
    grey: '#f5f6f8',
    teal: '#80cbc4',
    lightgrey: "#f5f5f5"
  },
  primary: {
    light: '#b39ddb',
    main: '#644593',
    dark: '#512da8',
    contrastText: '#ede7f6',
  },
  secondary: {
    light: '#ffecb3',
    main: '#ff9e43',
    dark: '#827017',
    contrastText: '#e0f7fa',
  }
}
export default {
  palette,
  typography: {
    fontFamily: [
      'FuturaBookC'
    ],
  },
  cart: {
    cartWrap: {
      position: 'relative',
      flexBasis: '30%',
    },
    cartContent: {
      backgroundColor: 'white',
      width: '100%',
      overflow: 'auto',
      height: 'calc(100vh - 2rem)',
      background: 'white',
      boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
      borderRadius: '10px'
    },
    cartTitleWrap: {
      height: '7%',
      background: palette.primary.main,
      padding: '10px 0',
      position: 'relative',
    },
    cartList: {
      padding: '10px',
      overflow: 'auto',
      height: '80%',
      borderBottom: '1px solid #e0e0e0'
    },
    finalStage: {
      width: '100%',
      display: 'flex',
      height: '13%',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '5px 10px'
    },
    cartTitle: {
      color: 'white',
      fontSize: '30px',
    },

    totalInfo: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    totalPromo: {
      width: '50%',
      display: 'flex',
      margin: '5px 0',
      alignItems: 'center',
      justifyContent: 'space-between',
    },

    flexBox: {
      display: 'flex',
      justifyContent: 'space-between'
    },

    promoInput: {
      width: '173px',
      marginRight: '10px',
    },
    totalPrice: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `1px solid ${palette.primary.main}`,
      marginLeft: '5px',
      borderRadius: '4px',
      width: '150px',
      padding: '2px',
      color: 'rgba(0, 0, 0, 0.7)'
    },
    backToCash: {
      position: 'absolute',
      top: 0,
      left: 0,
      color: 'white',
      fontSize: '30px',
      transform: 'translateY(-10%)'
    }
  },
  cartEdit: {
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '10px 0',

    },
    title: {
      color: deepPurple[900],
    },
    radioWrap: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    cartEditWrap: {
      position: 'relative'
    },
    closeEdit: {
      position: 'absolute',
      top: 0,
      right: 0,
    },
    errorMessage: {
      fontSize: '14px',
      color: 'red',
      fontWeight: 'normal',
    },
    priceMessage: {
      fontSize: '16px',
      fontWeight: 'normal',
    }
  },
  tables: {
    tableHeader: {
      fontWeight: 'bold',
      fontSize: '1rem'
    },
  },
  mainStyles: {
    flexbox: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    flexboxAlign: {
      alignItems: 'center'
    },
    navbar: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: 100,
      height: '100%',
    },
    button: {
      margin: '20px 0 0 0',
      height: 50,
      fontSize: '1.1rem',
    },
    progress: {
      position: 'absolute'
    },
    titleBlock: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      textTransform: 'uppercase',
      paddingLeft: '2rem',
      fontSize: '2.5rem',
      fontWeight: '600',
      fontFamily: 'FuturaBookC',
    },
    buttonWrap: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '5px 0',
    },
    mainWrap: {
      position: 'relative',
    },
  },
}

export const themeColors = {
  textColor: '#e6e6e6',
} 