/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { setDiscount, setInitialProducts } from '../../../redux/actions/promoActions';
import { returnInitialProducts } from '../../../redux/actions/cartActions';
import ConfirmAction from '../../../components/ConfirmAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHttp } from 'hooks/httpHook';
import { connect } from 'react-redux';
//MUI
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import DeleteIcon from '@material-ui/icons/Delete';
const styles = theme => ({
  ...theme.cart,
  ...theme.mainStyles,
  errorMessage: {
    color: 'red',
    display: 'block',
    height: '15px',
  },
  divider: {
    marginTop: '5px'
  },
  confirmButton: {
    position: 'relative',
  },
  promoInfo: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    borderBottom: '1px solid #C3C3C3',
    minWidth: '250px',
    paddingBottom: '10px',
    height: '50px',
  }
})
const CouponField = ({ setInitialProducts, returnInitialProducts, data: { orderId }, cart: { initialProducts, totalPrice, products }, promo: { code, success, type, percent, coupon_id, coupon_name }, promo, classes, setDiscount }) => {
  const [value, setValue] = useState('');
  const { request, loading, error, clearError } = useHttp();
  const [openDelete, setOpenDelete] = useState(false);

  useEffect(() => {
    setValue(code)
  }, [])

  const getDiscount = async () => {
    const sendingData = { code: value, total: totalPrice, order_id: orderId }
    const response = await request('/coupons', 'post', sendingData);
    if (response) {
      clearError();
      setDiscount(response, products, sendingData);
    }
  }
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const sendCoupon = (e) => {
    e.preventDefault();
    const sendData = async () => {
      setInitialProducts();
      await getDiscount();
    }
    sendData()
  }
  const handleChange = (e) => {
    let newValue = e.target.value.toLowerCase().split(' ').join('');
    setValue(newValue);
  }
  let text = coupon_name;

  const deleteButton = success ?
    <IconButton onClick={handleOpenDelete}>
      <DeleteIcon fontSize="large" style={{ color: red[500] }} />
    </IconButton> : null;

  const updateDeleteChanges = () => {
    clearError();
    setValue('');
    returnInitialProducts(type);
  }
  const deleteMethods = {
    method: 'DELETE',
    open: openDelete,
    handleClose: handleCloseDelete,
    question: 'Вы действительно хотите удалить купон?',
    updateList: updateDeleteChanges,
    url: `/coupons/${coupon_id}`
  }
  return (
    <>
      <ConfirmAction config={deleteMethods} />
      <form onSubmit={sendCoupon} className={classes.totalPromo}>
        <TextField onChange={handleChange} className={classes.promoInput} error={!!error.message} margin="dense" label="Введите промокод" value={value} variant="outlined" />
        <Grid className={classes.promoInfo}>
          {text}
        </Grid>
        <Button type="submit" disabled={success || loading} color="primary">Применить {loading ? <CircularProgress size={30} className={classes.progress} /> : null}</Button>
        {deleteButton}
      </form>
      <p className={classes.errorMessage}>{error.message}</p>
    </>
  )
}
const mapStateToProps = state => ({
  cart: state.cart,
  data: state.data,
  promo: state.promo
})
export default connect(mapStateToProps, { returnInitialProducts, setDiscount, setInitialProducts })(withStyles(styles)(CouponField));
