import React, { useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import './App.css';
import './normalize.css'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store';
import themeStyle from './util/theme'
import AuthRoute from './components/AuthRoute';
import AuthRedirect from 'components/AuthRedirect';
import axios from 'axios';
import { BASE_URI } from './services';
import KitchenStage from './pages/Kitchen/KitchenStage';
import { CustomerData, Cash, Menu, Login, PayPage, Kitchen, OrdersStatus } from './pages';
import Admin from './pages/Admin';
import SwitchPosition from './components/SwitchPosition';
import NoMatch from './pages/NoMatch';
import AdminTool from 'components/AdminTool';

const theme = createMuiTheme(themeStyle);
axios.defaults.baseURL = BASE_URI
axios.defaults.headers.common['Authorization'] = `${localStorage.getItem('FBIdToken')}`;
const role = localStorage.path;
const App = () => {
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <Router>
          <AuthRedirect />
          {
            role === 'admin' ? <AdminTool /> : null
          }
          <Switch>
            <Route exact path='/' component={SwitchPosition} />
            <Route
              exact
              path='/login'
              component={Login}
            />
            <Route
              exact
              path='/orders'
              component={OrdersStatus}
            />
            <AuthRoute
              exact
              path='/kitchen'
              component={Kitchen} />
            <AuthRoute
              exact
              path='/cash'
              component={Cash} />
            <AuthRoute
              exact
              path='/cash/pay'
              component={PayPage} />
            <AuthRoute
              exact
              path='/cash/menu'
              component={Menu} />
            <AuthRoute
              exact
              path='/cash/customer-info'
              component={CustomerData} />
            <AuthRoute
              exact
              path='/kitchen/:stage'
              component={KitchenStage} />
            <AuthRoute
              path='/admin'
              component={Admin} />
            <Route
              path='*'
              component={NoMatch} />
          </Switch>
        </Router>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
