import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import withStyles from '@material-ui/core/styles/withStyles';
import { ClickAwayListener } from '@material-ui/core';
import { Link } from 'react-router-dom';
//icons
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LocalPizzaIcon from '@material-ui/icons/LocalPizza';
import SettingsIcon from '@material-ui/icons/Settings';
import RightIcon from '@material-ui/icons/ChevronRight';
import LeftIcon from '@material-ui/icons/ChevronLeft';
const styles = theme => ({
  buttonsWrap: {
    position: 'fixed',
    bottom: '7rem',
    right: '4rem',
    transition: '0.5s',
    zIndex: 1000,

  },
  adminButton: {
    borderRadius: '50%',
    transition: '0.3s',
    background: theme.palette.primary.main,
  },
  linkButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    transition: '0.3s',
    background: theme.palette.primary.main,
    zIndex: -1,
  },
  toggleTool: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%) translateX(100%)',
    background: theme.palette.primary.light,
    transition: '0.5s',
    '&:hover': {
      background: theme.palette.primary.light,
    }
  }
})

const AdminTool = ({ classes }) => {
  const [opened, setOpened] = useState(false);
  const [hidden, setHidden] = useState(true);

  const toggleTool = () => {
    let hidden = null;
    setHidden(prev => {
      hidden = !prev;
      return !prev;
    });

    if (hidden) {
      setOpened(false)
    } else {
      setOpened(true);
    }
  }

  const hideToolStyle = {
    right: hidden ? '100%' : 0,
    transform: hidden ? 'translateY(-50%) translateX(0)' : 'translateY(-50%) translateX(100%)'
  }
  return (
    <>
      <ClickAwayListener onClickAway={() => setOpened(false)}>
        <Grid className={classes.buttonsWrap} style={{ right: hidden ? '0' : '4rem', transform: hidden ? 'translateX(100%)' : 'none' }}>
          <IconButton onClick={toggleTool} style={hideToolStyle} size="small" className={classes.toggleTool}>
            {
              hidden ? <LeftIcon /> : <RightIcon />
            }
          </IconButton>
          <Grid className={classes.adminButton}>
            <IconButton onClick={() => setOpened((prev) => !prev)}>
              <SettingsIcon style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid component={Link} to="/admin" style={{ transform: opened ? 'translateX(-50%) translateY(-200%)' : 'translateX(-50%) translateY(-50%)' }} className={classes.linkButton}>
            <IconButton size="medium">
              <PeopleAltIcon fontSize="small" style={{ color: 'white' }} />
            </IconButton>
          </Grid>

          <Grid component={Link} to="/kitchen" style={{ transform: opened ? 'translateX(-150%) translateY(-150%)' : 'translateX(-50%) translateY(-50%)' }} className={classes.linkButton}>
            <IconButton size="medium">
              <LocalPizzaIcon fontSize="small" style={{ color: 'white' }} />
            </IconButton>
          </Grid>
          <Grid component={Link} to="/cash" style={{ transform: opened ? 'translateX(-200%) translateY(-50%)' : 'translateX(-50%) translateY(-50%)' }} className={classes.linkButton}>
            <IconButton size="medium">
              <ShoppingCartIcon fontSize="small" style={{ color: 'white' }} />
            </IconButton>
          </Grid>
        </Grid>
      </ClickAwayListener >
    </>
  )
}

export default withStyles(styles)(AdminTool)