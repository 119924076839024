import React, { useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru"; 
import { useTime } from 'hooks/timeHook'
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import EventIcon from '@material-ui/icons/Event';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
const styles = theme => ({
  datePicker: {
    display: 'block',
  },
})
const AnalyticsSchedule = ({ classes, state, setState, dateStart, dateEnd }) => {
  const [startDate, setStartDate] = useState(!dateStart ? null : dateStart);
  const [finishDate, setFinishDate] = useState(!dateEnd ? null : dateEnd);
  const { formatDate } = useTime();
  const startDateChange = (date) => {
    setStartDate(date);
    let propperDate = formatDate(date, "YYYY-MM-DD");
    setState({
      ...state,
      date_start: propperDate
    })
  };
  const finishDateChange = date => {
    setFinishDate(date);
    let propperDate = formatDate(date, "YYYY-MM-DD");
    setState({
      ...state,
      date_end: propperDate
    })
  };
  return (
    <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
      <Grid >
        <KeyboardDatePicker
          autoOk={true}
          variant="inline"
          keyboardIcon={<EventIcon color="primary" />}
          name="date_start"
          format="dd/MM/yyyy"
          className={classes.datePicker}
          id="date-picker-start"
          label="Начальная дата"
          value={startDate}
          onChange={startDateChange}
        />
        <KeyboardDatePicker
          autoOk={true}
          variant="inline"
          keyboardIcon={<EventIcon color="primary" />}
          name="date_end"
          format="dd/MM/yyyy"
          className={classes.datePicker}
          id="date-picker-end"
          label="Конечная дата"
          value={finishDate}
          onChange={finishDateChange}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default withStyles(styles)(AnalyticsSchedule)
