import React from 'react'
import { connect } from 'react-redux';
import {closeProductInfo} from '../../../../redux/actions/cartActions';
//MUI 
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'; 
import DialogContent from '@material-ui/core/DialogContent';

const ProductInfo = (props) => {
  const {cart, closeProductInfo} = props;
  const {productInfo} = cart;  
  return (
    <Dialog open={productInfo.opened} onClose={closeProductInfo} fullWidth maxWidth="sm">
      <DialogTitle>{productInfo.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{productInfo.description}</DialogContentText> 
      </DialogContent>
    </Dialog>
  )
}
const mapStateToProps = state => ({
  cart: state.cart
})
export default connect(mapStateToProps, {closeProductInfo})(ProductInfo)
