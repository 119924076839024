/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { Context } from 'hooks/contextHook';
import SearchInput from 'components/SearchInput';
import { useHttp } from 'hooks/httpHook';
import ProductWindow from './ProductWindow';
import TableSkeleton from 'util/skeletons/UserTableSkeleton'
//MUI
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
//icons
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';

const styles = theme => ({
  ...theme.tables,
  cardWrapper: {
    marginBottom: theme.spacing(2),
    width: '49.5%'
  },
  tableTools: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cellName: {
    transition: '0.3s',
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  ingredientDescription: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingBottom: '10px'
  },
  ingredientItem: {
    width: '30%',
    marginRight: '3%',
    padding: '5px',
    marginBottom: theme.spacing(1),
    borderRadius: '5px',
    border: `1px solid rgba(0, 0, 0, 0.2)`,
  }
})

const ProductsTable = ({ classes }) => {
  const [value, setValue] = useState('');
  const [packageState, setPackageState] = useState(true);
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState({});
  const [active, setActive] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { request, loading } = useHttp();
  const [products, setProducts] = useState([]);
  const { updated } = useContext(Context);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (value.length) {
      setPage(0);
    }
  }, [value])

  const getData = async () => {
    const data = await request('/admin/ingredient/products', 'get');
    if (data) {
      setProducts(data);
    }
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    if (updated) {
      getData();
    }
  }, [updated])

  return (
    <>
      <ProductWindow
        loading={loading}
        products={products}
        getData={getData}
        handleClose={handleClose}
        open={open}
        item={product}
      />
      <Card className={classes.cardWrapper}>
        <CardContent>
          <CardHeader title="Позиции" />
          <Toolbar className={classes.tableTools}>
            <SearchInput setValue={setValue} />
          </Toolbar>
          <Table size="small" className={classes.table} >
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeader}>Название позиции</TableCell>
                <TableCell className={classes.tableHeader} align="right"/>
              </TableRow>
            </TableHead>
            <ClickAwayListener onClickAway={() => setActive(null)}>
              <TableBody>
                {!loading ? products
                  .filter((item) => {
                    let name = item.name.toLowerCase();
                    let inputValue = value.toLowerCase();
                    if (name.indexOf(inputValue) + 1) {
                      return item;
                    } else {
                      return null;
                    }
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item) => (
                    <React.Fragment key={item.id}>
                      <TableRow className={classes.cellName} key={item.name} onClick={() => {
                        if (active === item.id) {
                          setActive(null)
                        } else {
                          setActive(item.id)
                        }
                      }}>
                        <TableCell component="th" scope="row">
                          {item.name}
                        </TableCell>
                        <TableCell align="right">
                          <IconButton size="small" onClick={() => {
                            setProduct(item);
                            handleClickOpen();
                          }}>
                            <EditIcon color="secondary" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={Boolean(active === item.id)} timeout="auto" unmountOnExit>
                            <FormControlLabel
                              control={<Switch checked={packageState} onChange={() => setPackageState(prev => !prev)} name="checkedA" />}
                              label="Учитывать упаковку"
                            />
                            <Grid className={classes.ingredientDescription}>
                              {
                                item.options.map((option, i) => {
                                  return (
                                    <Grid key={i} className={classes.ingredientItem}>
                                      <p>{option.dough_size} - {option.dough_type}</p>
                                      <p>Вес: <span style={{ fontWeight: 'bold' }}>{option.weight}г.</span></p>
                                      <p>Цена: <span style={{ fontWeight: 'bold' }}>
                                        {packageState ? (+option.price + +option.package_price).toFixed(2) : option.price}руб.</span></p>
                                    </Grid>
                                  )
                                })
                              }
                            </Grid>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )) : <TableSkeleton />}
              </TableBody>
            </ClickAwayListener>
          </Table>
           <TablePagination
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
            labelRowsPerPage="Элементов на странице"
            rowsPerPageOptions={[10, 25, products.length]}
            component="div"
            count={products.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </CardContent>
      </Card>
    </>
  )
}

export default withStyles(styles)(ProductsTable);