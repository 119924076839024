import React from 'react';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import themeStyle from '../../../util/theme';
const styles = (theme) => ({
  itemTitle: {
    fontWeight: '600',
    fontSize: '50px',
    lineHeight: 1.1,
  }
})
const OrderItem = (props) => {
  const {data, ready, classes} = props;
  console.log(data)
  return (
    <Typography style={ready ? {color: themeStyle.palette.colors.active, fontSize: '60px'} : {color: themeStyle.palette.primary.main}} className={classes.itemTitle} variant="h6">
      {data.id}
    </Typography>
  )
}

export default withStyles(styles)(OrderItem)
