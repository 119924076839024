import {
  GET_CATEGORIES,
  LOADING_CATEGORIES,
  LOADING_ORDERS,
  GET_ORDERS,
  CHANGE_ORDERS,
  GET_PROGRESS_ORDERS,
  GET_READY_ORDERS,
  SWITCH_OFF_SOUND,
  GET_HOLDING_ORDERS,
  START_ORDER,
  SET_ORDER_TIME,
  START_DELIVERY_ORDER,
} from "../types";

const initialState = {
  loadingCategory: false,
  categories: [],
  products: [],
  orderStarted: false,
  kitchenOrders: [],
  tvOrders: {},
  orderId: null,
  sound: false,
  errors: null,
  orderData: {
    time: null,
    date: null,
  },
  categoriesLoaded: false,
  loadingOrders: false,
  deliveryOrder: false,
};

export default function (
  state = initialState,
  { type, payload, orderId, checkId }
) {
  switch (type) {
    case SET_ORDER_TIME: {
      return {
        ...state,
        orderData: {
          ...payload,
        },
      };
    }
    case START_ORDER:
      return {
        ...state,
        orderStarted: true,
      };
    case START_DELIVERY_ORDER:
      return {
        ...state,
        deliveryOrder: true,
      };
    case GET_PROGRESS_ORDERS:
      return {
        ...state,
        tvOrders: {
          ...state.tvOrders,
          progress: payload,
        },
      };
    case GET_READY_ORDERS:
      return {
        ...state,
        tvOrders: {
          ...state.tvOrders,
          ready: payload,
        },
      };
    case GET_HOLDING_ORDERS:
      return {
        ...state,
        tvOrders: {
          ...state.tvOrders,
          holding: payload,
        },
      };
    case LOADING_ORDERS:
      return {
        ...state,
        loadingOrders: true,
      };
    case SWITCH_OFF_SOUND:
      return {
        ...state,
        sound: false,
      };
    case CHANGE_ORDERS:
      if (state.kitchenOrders.length < payload.length) {
        return {
          ...state,
          sound: true,
          kitchenOrders: payload,
        };
      } else {
        return {
          ...state,
          kitchenOrders: payload,
        };
      }
    case GET_ORDERS:
      return {
        ...state,
        loadingOrders: false,
        kitchenOrders: payload,
      };
    case LOADING_CATEGORIES:
      return {
        ...state,
        loadingCategory: true,
      };
    case GET_CATEGORIES:
      return {
        ...state,
        orderId,
        checkId,
        loadingCategory: false,
        categoriesLoaded: true,
        categories: payload,
      };
    default:
      return state;
  }
}

//Создание скидки
const makeDiscount = (array, discount) => {
  return array.map((category) => {
    return {
      ...category,
      products: category.products.map((product) => {
        return {
          ...product,
          price: Math.floor(product.price * (1 - discount / 100)),
          options: product.options.map((option) => {
            return {
              ...option,
              price: Math.floor(option.price * (1 - discount / 100)),
            };
          }),
        };
      }),
    };
  });
};
