/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { getCategories } from '../../redux/actions/dataActions';
import { Scrollbars } from 'react-custom-scrollbars';
import { recountTotalPrice, submitPayment } from '../../redux/actions/cartActions';
import { setDiscount } from '../../redux/actions/promoActions';
import { useHttp } from 'hooks/httpHook';
import MenuSkeleton from '../../util/skeletons/MenuSkeleton';
import Category from '../Menu/Categories/Category'
import Cart from '../Cart';
import ProductInfo from '../Menu/Categories/ProductInfo';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';

const styles = (theme) => ({
  mainWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100vw - 2rem)',
    height: 'calc(100vh - 2rem)',
    margin: theme.spacing(2)
  },
  categories: {
    flexBasis: '69%',
    overflow: 'auto',
    padding: theme.spacing(1),
  },
})

const Menu = ({ setDiscount, submitPayment, classes, getCategories, recountTotalPrice, promo, promo: { percent, type }, data: { orderId, orderStarted, categories, loadingCategory, categoriesLoaded }, cart: { order, firstTimeAdded, totalPrice, products, submitCount } }) => {
  const [loadedProducts, setLoadedProducts] = useState(false);
  const { request, error } = useHttp();
  useEffect(() => {
    if (categoriesLoaded === false) {
      if (orderStarted) {
        getCategories();
      }
    }
  }, [orderStarted]);

  useEffect(() => {
    if (percent.discount) {
      recountTotalPrice(percent.discount);
    } else {
      if (type) {
        recountTotalPrice(null, type);
      } else {
        recountTotalPrice();
      }
    }
    if (submitCount > 0) {
      submitPayment();
    }
  }, [products]);

  useEffect(() => {
    if (orderId) {
      document.title = 'Меню - Marti\'s CRM'
      const getDiscount = async () => {
        const sendingData = { code: "0", total: totalPrice, order_id: orderId }
        const response = await request('/coupons', 'post', sendingData);
        if (response) {
          setDiscount(response, products, sendingData);
        }
      }
      if (order.platform_id === 4 || order.platform_id === 3) {
        return;
      }
      if (!firstTimeAdded) {
        getDiscount();
      }
    }
  }, [orderId])

  const changeProductsStatus = () => {
    setLoadedProducts(true);
  }
  let categoryWrap = !loadingCategory ? (categories.map(item => <Category loadedProducts={loadedProducts} changeProductsStatus={changeProductsStatus} key={item.id} category={item} />)) : <MenuSkeleton />;
  return (
    <div className={classes.mainWrap}>
      <Card className={classes.categories}>
        <Scrollbars style={{ height: '90vh' }}>
          {categoryWrap}
        </Scrollbars>
      </Card>
      <Cart />
      <ProductInfo />
    </div>
  )
}
Menu.propTypes = {
  data: PropTypes.object.isRequired
}
const mapStateToProps = (state) => ({
  data: state.data,
  cart: state.cart,
  promo: state.promo
})
export default connect(mapStateToProps, { setDiscount, submitPayment, recountTotalPrice, getCategories })(withStyles(styles)(Menu));