/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Context }    from 'hooks/contextHook';
import WriteOffSelect from '../WriteOffSelect';
import { useHttp }    from 'hooks/httpHook';
import { useTime }    from 'hooks/timeHook';

//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog        from '@material-ui/core/Dialog';
import Grid          from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Button        from '@material-ui/core/Button';
import Typography    from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Alert         from '@material-ui/lab/Alert';
import Snackbar      from '@material-ui/core/Snackbar';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//icons
import CloseIcon        from '@material-ui/icons/Close';
import IconButton       from '@material-ui/core/IconButton';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox       from "@material-ui/core/Checkbox";
const styles = theme => ({
  formControl: {
    minWidth: 120,
    width: '48%'
  },
  selectWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    border: '1px solid grey',
    borderRadius: '5px'
  },
  select: {
    marginBottom: theme.spacing(1),
  },
  addedList: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    marginBottom: theme.spacing(1),
    padding: '5px 5px 5px 10px',
    background: theme.palette.primary.main,
    borderRadius: '50px',
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  closeButton: {
    marginLeft: '5px',
    background: 'white',
    '&:hover': {
      background: theme.palette.secondary.main
    }
  }
})

const WriteOffWindowIngredient = ({ classes, handleClose, open, getData }) => {
  const { products } = useContext(Context);
  const { formatDate } = useTime();
  const [positions, setPositions] = useState([]);
  const [addedList, setAddedList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()), "YYYY-MM-DD");
  const { request, error, success } = useHttp();

  useEffect(() => {
    if (products.length) {
      let sortedProducts = sortArray(products);
      setPositions(sortedProducts);
    }
  }, [products]);

  const sortArray = (items) => {
    let initialArray = items;
    let newArray = []
    for (let i = 0; i <= initialArray.length; i++) {
      let categoryName = initialArray[0].category;
      let firstArray = initialArray.filter(a => a.category === categoryName);
      if (firstArray.length) {
        newArray.push(firstArray);
        initialArray = initialArray.filter(a => a.category !== categoryName);
      }
    }
    return newArray;
  }

  const deleteItemList = (item) => {
    let newList = addedList.filter(a => item.name !== a.name);
    setAddedList(newList)
  }

  const sendItems = async () => {
    let ingredients = addedList.map(i => {
      i.created_at = formatDate(selectedDate, "YYYY-MM-DD");

      return i;
    })
    setAddedList([]);
    await request('/admin/analytics/products/writeoffingredient', 'post', { ingredients }).then(res => window.location.reload());
  }

  const handleChangeWeight = (value, item) => {
    item.weight = parseInt(value) || 0;
    let arr = addedList.filter(a => item.name !== a.name)

    arr = [...arr, item]
    setAddedList(arr)
  }

  const handleDateChange = value => {
    setSelectedDate(value)
  }

  useEffect(() => {
    setTimeout(() => {
      handleClose();
      getData();
    }, 2500)
  }, [success])

  return (
    <Dialog fullWidth={true}
      maxWidth={'sm'}
      onClose={handleClose}
      open={open}>
      <DialogTitle id="simple-dialog-title">Добавление списания</DialogTitle>
      <DialogContent>
        <Grid style={{marginBottom: '5px', display: 'flex'}}>
          <Typography variant={'h6'}>Дата: </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              id="date-picker-inline"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{padding: '0 5px'}}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid className={classes.selectWrap}>
          {
            positions.length && positions.map((category, index) => <WriteOffSelect
              products={products}
              key={index}
              category={category}
              currentDate={selectedDate}
              addedList={addedList}
              updateList={setAddedList} />)
          }
        </Grid>
        <Grid className={classes.addedList}>
          {
            addedList.length ? addedList.map((item) => {
              return (
                <Grid key={item.ingredient_id} className={classes.listItem}>
                  <Typography variant="body2">{item.name}</Typography>
                  <input style={{width: '40px', margin: '0px 5px'}} value={item.weight} onChange={(e) => handleChangeWeight(e.target.value, item)} /> г.
                  <IconButton onClick={() => deleteItemList(item)} size="small" className={classes.closeButton}>
                    <CloseIcon size="small" />
                  </IconButton>
                </Grid>
              )
            }) : null
          }
        </Grid>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>Закрыть</Button>
          <Button color="primary" onClick={sendItems} variant="contained">Добавить</Button>
        </DialogActions>
      </DialogContent>
      <Snackbar
        open={!!success}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="success">Данные успешно отправлены</Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="error">Произошла ошибка, попробуйте позже</Alert>
      </Snackbar>
    </Dialog>
  )
}

export default withStyles(styles)(WriteOffWindowIngredient)

