/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import withStyles from '@material-ui/core/styles/withStyles';
import DateSchedule from 'components/DateSchedule';
import { useTime } from 'hooks/timeHook';
import RadioDates from './RadioDates'
import FinanceDiagram from './FinanceDiagram';
//MUI
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';


const styles = theme => ({
  totalAnalytics: {
    width: '100%',
    padding: theme.spacing(2)
  },
  defaultValue: {
    opacity: '0.6',
    display: 'block',
    margin: '15px 0'
  },
  analyticsWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  orderDate: {
    height: '240px',
    marginBottom: '1rem',
    padding: theme.spacing(1),
    background: theme.palette.colors.lightgrey
  },
  
})
const OrdersTab = ({ classes }) => {
  const [data, setData] = useState({});
  const [dateState, setDateState] = useState({});
  const [monthAgo, setMonthAgo] = useState({});
  const [twoMonthsAgo, setTwoMonthsAgo] = useState({});
  const { timeAgoDates } = useTime();
  const [weekdays, setWeekdays] = useState({})

  const getRevenueData = () => {
    if (!Object.keys(dateState).length) {
      axios.get('/admin/analytics/days')
        .then(res => {
          setData(res.data)
        })
        .catch(err => console.error(err.data))
    } else {
      axios.post('/admin/analytics/days', timeAgoDates(dateState))
        .then(res => {
          setData(res.data)
        })
        .catch(err => console.error(err.data))
    }

    axios.post('/admin/analytics/days', timeAgoDates(dateState, 'month', 1))
      .then(res => {
        setMonthAgo({ ...res.data });
      })
      .catch(err => console.error(err.data))

    axios.post('/admin/analytics/days', timeAgoDates(dateState, 'month', 2))
      .then(res => {
        setTwoMonthsAgo({ ...res.data });
      })
      .catch(err => console.error(err.data))
  }

  useEffect(() => {
    getRevenueData();
    if (Object.keys(dateState).length) {
      setWeekdays({
        actualDate: timeAgoDates(dateState).weekDay,
        monthAgo: timeAgoDates(dateState, 'month', 1).weekDay,
        twoMonthsAgo: timeAgoDates(dateState, 'month', 2).weekDay,
      })
    }
  }, [dateState])

  const dateConfig = {
    flexWrap: true,
    dateState,
    setDateState,
  }

  return (
    <Grid className={classes.totalAnalytics}>
      <Card className={classes.orderDate}>
        <Typography variant="h5">
          Данные по заказам
        </Typography>
        <DateSchedule {...dateConfig} />
        <RadioDates dateState={dateState} setDateState={setDateState} />
      </Card > 
      <FinanceDiagram weekdays={weekdays} data={data} monthAgoData={monthAgo} twoMonthAgoData={twoMonthsAgo} />
    </Grid>
  )
}

export default withStyles(styles)(OrdersTab)
