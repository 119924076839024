/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
//MUI 
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles'
const styles = theme => ({

  variationItem: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0 1% 1%',
    width: '100px',
    height: '50px',
    border: `1px solid rgba(0, 0, 0, 0.2)`,
    borderRadius: '5px'
  },
})

const IngredientItem = ({ active, setActive, item, classes }) => {
  return (
    <>
      <Grid
        className={classes.variationItem}
        style={{ cursor: 'pointer', background: active.name === item.name ? '#b39ddb' : null }} onClick={() => setActive(item)} >
        <Typography>
          {item.name}
        </Typography>
      </Grid>
    </>
  )
}

export default withStyles(styles)(IngredientItem);
