/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Context } from 'hooks/contextHook';
import { useHttp } from 'hooks/httpHook';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green, red } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  collapseActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(1)
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonError: {
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
})
const ActionsCollapse = ({ onSubmit, config, classes, item, label }) => {
  const [value, setValue] = useState(config.initial_value);
  const { request, loading, error, success, clearError } = useHttp();
  const { setUpdated } = useContext(Context);

  const sendData = async (e) => {
    e.preventDefault();
    onSubmit(value);
    await request(config.path, config.method, { [config.bodyKey]: value })
  }

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        clearError();
        setUpdated(true);
      }, 1500)
    }
  }, [success])
  return (
    <>
      <form onSubmit={sendData}>
        <Grid className={classes.collapseActions}>
          <TextField autoFocus label={label} value={value} onChange={(e) => {
            let value = e.target.value.replace(",", ".");
            setValue(value);
          }} />
          <div className={classes.wrapper}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              className={success ? classes.buttonSuccess : error ? classes.buttonError : null}
            >
              Сохранить
        </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </Grid>
      </form>
      {
        !!error ? (
          <FormControl error={!!error}>
            <FormHelperText>Что-то пошло не так</FormHelperText>
          </FormControl>
        ) : null
      }
    </>
  )
}

export default withStyles(styles)(ActionsCollapse)
