import React from 'react'
//MUI
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  cashOrderItem: {
    padding: '10px 0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  orderItemName: {
    flexBasis: '50%',
  }
})

const CashOrderItem = (props) => {
  const { item, item: { options, info }, classes } = props;
  let content = null;
  if (options.length >= 1) {
    let pizzaOption = options[0];
    let sauceOption = options[1] ? `Соус ${options[1].value}` : `Без соуса`
    content = (
      <Grid>
        <p>{`${pizzaOption.name} ${pizzaOption.value}см`}, {sauceOption}</p>
      </Grid>
    )
  }
  return (
    <Grid>
      <Grid className={classes.cashOrderItem}>
        <Grid className={classes.orderItemName}>
          <Typography variant="h6">{info.name}</Typography>
          {content}
        </Grid>
        <p>{item.price}руб.</p>
      </Grid>
      <Divider />
    </Grid>
  )
}

export default withStyles(styles)(CashOrderItem)
