import React, { useState, useEffect } from 'react'
import { pink } from '@material-ui/core/colors';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import IconButton from '@material-ui/core/IconButton';

const HtmlTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: pink[700],
    color: 'white',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const HintIcon = ({ children, config }) => {
  const { actionFunc, text, placement, opened, alwaysShow } = config;
  const [openTip, setOpenTip] = useState(false);
  const handleTooltipClose = () => {
    if (alwaysShow) {
      return;
    }
    setOpenTip(false);
  };

  const handleTooltipOpen = () => {
    setOpenTip(true);
    if (actionFunc) {
      actionFunc();
    }
  };
  useEffect(() => {
    if (opened) {
      setOpenTip(true)
    }
  }, [])
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <HtmlTooltip
        title={text}
        placement={placement}
        open={openTip}
        onClose={handleTooltipClose}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton ref={config.ref || null} onClick={handleTooltipOpen} className={config.class}>
          {children}
        </IconButton>
      </HtmlTooltip>
    </ClickAwayListener>
  )
}

export default HintIcon
