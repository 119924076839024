import * as Yup from 'yup';

export default function blackPhoneSchema() {
    return Yup.object().shape({
        porch: Yup.string()
            .required('Введите подъезд'),
        floor: Yup.string()
            .required('Введите этаж'),
        flat: Yup.string()
            .required('Введите квартиру'),
        reason: Yup.string()
            .required('Введите причину'),
    });
}
