import React, { useState, useEffect } from 'react';
import ConstituentsWindow from './ConstituentsWindow';
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import ConfirmAction from 'components/ConfirmAction';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
import SearchInput from 'components/SearchInput';
import TableSkeleton from 'util/skeletons/UserTableSkeleton'
//icons
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
const styles = theme => ({
  ...theme.tables,
  cardWrapper: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
  },
  cellName: {
    transition: '0.3s',
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  tableTools: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  ingredientDescription: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingBottom: '10px'
  },
  ingredientItem: {
    width: '30%',
    marginRight: '3%',
    padding: '5px',
    marginBottom: theme.spacing(1),
    borderRadius: '5px',
    border: `1px solid rgba(0, 0, 0, 0.2)`,
  }
})

const ConstituentsTable = ({ loading, classes, getData, constituents }) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [constituent, setConstituent] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [active, setActive] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleOpen = () => {
    setOpen(true);
  }

  const handleEditConstituent = (item) => {
    setConstituent(item)
    setOpen(true)
  }

  const handleOpenDelete = (item) => {
    setConstituent(item);
    setOpenDelete(true);
  }

  const handleCloseDelete = () => {
    setOpenDelete(false);
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (!open) {
      setConstituent({});
    }
  }, [open])
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteMethods = {
    method: 'DELETE',
    open: openDelete,
    handleClose: handleCloseDelete,
    question: `Вы действительно хотите удалить составляющее ${constituent.name}?`,
    updateList: getData,
    url: `/admin/constituents/${constituent.id}`
  }


  return (
    <>
      <ConfirmAction config={deleteMethods} />
      <ConstituentsWindow handleClose={handleClose} open={open} getData={getData} constituent={constituent} />
      <Grid className={classes.cardWrapper}>
        <Toolbar className={classes.tableTools}>
          <SearchInput setValue={setValue} />
          <Button color="primary" onClick={handleOpen} variant="contained">
            <AddIcon />
          </Button>
        </Toolbar>
        <Table size="small" className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Название</TableCell>
              <TableCell className={classes.tableHeader}>Цена за кг</TableCell>
              <TableCell className={classes.tableHeader} align="right"></TableCell>
            </TableRow>
          </TableHead>
          <ClickAwayListener onClickAway={() => setActive(null)}>
            <TableBody>
              {!loading ? constituents
                .filter((item) => {
                  let name = item.name.toLowerCase();
                  let inputValue = value.toLowerCase();
                  if (name.indexOf(inputValue) + 1) {
                    return item;
                  } else {
                    return null;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <React.Fragment key={item.id}>
                    <TableRow className={classes.cellName} key={item.name} onClick={() => {
                      if (active === item.id) {
                        setActive(null)
                      } else {
                        setActive(item.id)
                      }
                    }}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">{item.name}</Typography>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2">{item.price}руб.</Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="small" onClick={() => handleEditConstituent(item)} >
                          <EditIcon color="secondary" />
                        </IconButton>
                        <IconButton size="small" onClick={() => handleOpenDelete(item)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={active === item.id} timeout="auto" unmountOnExit>
                          <Typography variant="body2">Цена за кг: {constituent.price}руб.</Typography>
                          <Grid className={classes.ingredientDescription}>
                            {
                              item.options ? item.options.map((option, index) => {
                                let optionPrice = (constituent.price / 1000 * option.weight).toFixed(2);
                                return (
                                  <Grid key={index} className={classes.ingredientItem}>
                                    <p>{option.dough_size} - {option.dough_type}</p>
                                    <p>Вес: <span style={{ fontWeight: 'bold' }}>{option.weight}г.</span></p>
                                    <p>Цена: <span style={{ fontWeight: 'bold' }}>{optionPrice}руб.</span></p>
                                  </Grid>
                                )
                              }) : null
                            }
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )) : <TableSkeleton />}
            </TableBody>
          </ClickAwayListener>
        </Table>
        <TablePagination
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          labelRowsPerPage="Элементов на странице"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={constituents.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Grid>
    </>
  )
}

export default withStyles(styles)(ConstituentsTable);
