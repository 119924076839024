//DATA
export const LOADING_ORDERS = "LOADING_ORDERS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_ERRORS = "SET_ERRORS";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const LOADING_CATEGORIES = "LOADING_CATEGORIES";
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_ORDERS = "GET_ORDERS";
export const START_ORDER = "START_ORDER";
export const START_DELIVERY_ORDER = "START_DELIVERY_ORDER";
export const SET_ORDER_TIME = "SET_ORDER_TIME";
export const START_SERVICE_ORDER = "START_SERVICE_ORDER";
//CART
export const SEND_DATA_TO_ORDER = "SEND_DATA_TO_ORDER";
export const FIRST_TIME_ADD = "FIRST_TIME_ADD";
export const UPDATE_COMBO_IN_CART = "UPDATE_COMBO_IN_CART";
export const SET_INITIAL_PRODUCTS = "SET_INITIAL_PRODUCTS";
export const EDIT_COMBO = "EDIT_COMBO";
export const ADD_COMBO_TO_CART = "ADD_COMBO_TO_CART";
export const CHECK_COMBO_COMBINATION = "CHECK_COMBO_COMBINATION";
export const CLOSE_MAKE_COMBO = "CLOSE_MAKE_COMBO";
export const RETURN_INITIAL_PRODUCTS = "RETURN_INITIAL_PRODUCTS";
export const ADD_TO_CART = "ADD_TO_CART";
export const RECOUNT_TOTAL_PRICE = "RECOUNT_TOTAL_PRICE";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const BACK_TO_LIST = "BACK_TO_LIST";
export const ADD_PIZZA_TO_CART = "ADD_PIZZA_TO_CART";
export const EDIT_PIZZA = "EDIT_PIZZA";
export const SUBMIT_EDIT_PIZZA = "SUBMIT_EDIT_PIZZA";
export const SET_PHONE = "SET_PHONE";
export const OPEN_PRODUCT_INFO = "OPEN_PRODUCT_INFO";
export const CLOSE_PRODUCT_INFO = "CLOSE_PRODUCT_INFO";
export const CHANGE_ORDERS = "CHANGE_ORDERS";
export const CHANGE_COMMENT = "CHANGE_COMMENT";
export const CHANGE_PRICE_THROUGH_SERVICE = "CHANGE_PRICE_THROUGH_SERVICE";
//PAYMENT
export const SUBMIT_PAYMENT = "SUBMIT_PAYMENT";
export const TOGGLE_SUCCESS_PAYMENT = "TOGGLE_SUCCESS_PAYMENT";
export const MAKE_REORDER = "MAKE_REORDER";
export const CANCEL_REORDER = "CANCEL_REORDER";
//PROMO
export const PERCENT_DISCOUNT_COUPON = "PERCENT_DISCOUNT_COUPON";
export const ROUBLES_DISCOUNT_COUPON = "ROUBLES_DISCOUNT_COUPON";
export const PRODUCT_COUPON = "PRODUCT_COUPON";
export const PEPPERONI_COUPON = "PEPPERONI_COUPON";
export const BIRTHDAY_COUPON = "BIRTHDAY_COUPON";
export const FIRST_ORDER_COUPON = "FIRST_ORDER_COUPON";
export const PROMOCODE_ERROR = "PROMOCODE_ERROR";
export const ADD_PROMO_PIZZA = "ADD_PROMO_PIZZA";
//CASH
export const GET_PROGRESS_ORDERS = "GET_PROGRESS_ORDERS";
export const GET_READY_ORDERS = "GET_READY_ORDERS";
export const GET_HOLDING_ORDERS = "GET_HOLDING_ORDERS";
//KITCHEN
export const SWITCH_OFF_SOUND = "SWITCH_OFF_SOUND";
//ADMIN
export const GET_USERS = "GET_USERS";
export const GET_COUPONS = "GET_COUPONS";
export const GET_ORDERS_BASE = "GET_ORDERS_BASE";
export const GET_CUSTOMERS = "GET_CUSTOMERS";
