import React, { useState } from 'react'
import CouponDates from '../CouponDates';
import axios from 'axios';
import { useFormik } from 'formik';
import validationSchema from './schema'
//MUI 
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = theme => ({
  radioWrap: {
    display: 'flex',
    flexDirection: 'row'
  },
  formControl: {
    width: '100%',
  },

})
const AddCoupon = ({ coupon, classes, open, handleClose, getCoupons }) => {
  const { description, code, discount, total, quantity, type, is_hold_telephone, date_start, date_end, status } = coupon;
  const [state, setState] = useState({});
  const formik = useFormik({
    initialValues: {
      description: description || '',
      code: code || '',
      discount: discount || 0,
      total: total || 0,
      quantity: quantity || 0,
      type: type || '',
      is_hold_telephone: is_hold_telephone || 0,
      status: status || 0,
      date_start: date_start || null,
      date_end: date_end || null
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      if (!coupon.description) {
        axios.post('/admin/coupons', {
          ...values,
          ...state,
          status: 1
        })
          .then(() => {
            getCoupons();
            handleClose()
          })
          .catch(err => console.error(err.response.data));
      } else {
        axios.put(`/admin/coupons/${coupon.id}`, {
          ...values,
          ...state
        })
          .then(() => {
            getCoupons();
            handleClose();
          })
          .catch(err => console.error(err.response.data));
      }
    },
  })
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="sm"
    >
      <form onSubmit={formik.handleSubmit}>
        <DialogTitle id="form-dialog-title">{!coupon.description ? 'Добавление промокода' : 'Редактирование промокода'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={6} >
              <TextField
                id="name"
                name="description"
                label="Название промокода"
                type="text"
                fullWidth
                error={!!(formik.touched.description && formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description ? formik.errors.description : null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.description}
              />
              <TextField
                name="code"
                label="Код промокода"
                type="text"
                fullWidth
                error={!!(formik.touched.code && formik.errors.code)}
                helperText={formik.touched.code && formik.errors.code ? formik.errors.code : null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.code}
              />
              <TextField
                name="discount"
                label="Размер скидки"
                type="text"
                fullWidth
                error={!!(formik.touched.discount && formik.errors.discount)}
                helperText={formik.touched.discount && formik.errors.discount ? formik.errors.discount : null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.discount}
              />
              <FormControl className={classes.formControl}>
                <InputLabel id="type">Тип скидки</InputLabel>
                <Select
                  labelId="type"
                  name="type"
                  fullWidth
                  error={!!(formik.touched.type && formik.errors.type)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.type}
                >
                  <MenuItem value="%">Проценты</MenuItem>
                  <MenuItem value="rub">Рубли</MenuItem>
                  <MenuItem value="1">Первый заказ</MenuItem>
                  <MenuItem value="d">Двойной</MenuItem>
                  <MenuItem value="h">День рождения</MenuItem>
                  <MenuItem value="p">Подарок</MenuItem>
                </Select>
              </FormControl>
              <TextField
                name="total"
                label="Минимальная сумма заказа"
                type="text"
                fullWidth
                error={!!(formik.touched.total && formik.errors.total)}
                helperText={formik.touched.total && formik.errors.total ? formik.errors.total : null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.total}
              />
            </Grid>
            <Grid item lg={6} >
              <TextField
                name="quantity"
                label="Количество"
                type="text"
                fullWidth
                error={!!(formik.touched.quantity && formik.errors.quantity)}
                helperText={formik.touched.quantity && formik.errors.quantity ? formik.errors.quantity : null}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.quantity}
              />
              <FormControl className={classes.formControl}>
                <InputLabel id="phone-connection">Привязка к телефону</InputLabel>
                <Select
                  labelId="is_hold_telephone"
                  name="is_hold_telephone"
                  fullWidth
                  error={!!(formik.touched.is_hold_telephone && formik.errors.is_hold_telephone)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.is_hold_telephone}
                >
                  <MenuItem value="1">Есть</MenuItem>
                  <MenuItem value="0">Отсутствует</MenuItem>
                </Select>
              </FormControl>
              <FormControl className={classes.formControl}>
                <InputLabel id="phone-connection">Активен</InputLabel>
                <Select
                  labelId="status"
                  name="status"
                  fullWidth
                  error={!!(formik.touched.status && formik.errors.status)}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.status}
                >
                  <MenuItem value="1">Да</MenuItem>
                  <MenuItem value="0">Нет</MenuItem>
                </Select>
              </FormControl>
              <CouponDates dateStart={formik.values.date_start} dateEnd={formik.values.date_end} formik={formik} state={state} setState={setState} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
          <Button type="submit" color="primary" variant="contained">
            {!coupon.description ? 'Добавить' : 'Изменить'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default withStyles(styles)(AddCoupon)
