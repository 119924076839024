import React from 'react'
//MUI
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
const NoMatch = () => {
  return (
    <Grid>
      <Typography variant="h1">
        404 error
      </Typography>
    </Grid>
  )
}

export default NoMatch
