/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import ReactEcharts from 'echarts-for-react';
import currency from 'util/currency'
//MUI
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  orderDiagram: {
    height: '400px',
    width: '50%', 
    background: theme.palette.colors.lightgrey
  },
  flexWrap: {
    height: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  pie: {
    width: '50%',
  },
  pieStatistics: {
    width: '49%'
  },
  statistics: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    background: theme.palette.primary.light,
    lineHeight: 1.2
  },
  statisticsMain: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    background: theme.palette.primary.main,
    color: 'white',
    lineHeight: 1.2
  },
  cardTitle: {
    fontSize: '1.2rem'
  }

})

const FinanceDiagram = ({ weekdays, classes, data, monthAgoData, twoMonthAgoData, data: { total, cod_total, card_total, cod, card, orders } }) => {
  const [option, setOption] = useState({
    legend: {
      orient: 'vertical',
      left: 10,
      data: ['Наличные', 'Карта']
    },
    series: [
      {
        name: 'none',
        type: 'pie',
        radius: ['70%', '90%'],
        avoidLabelOverlap: false,
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '15',
            fontWeight: 'bold',
            formatter: function (params) {
              return `${params.name} - ${params.value} \n (Заказов: ${params.data.count})`
            },
            textStyle: {
              baseline: 'top'
            }
          }
        },
        labelLine: {
          show: true
        },
        data: [
          {
            itemStyle: {
              color: '#644593'
            },
            value: 0,
            count: 0,
            name: 'Наличные',
          },
          {
            itemStyle: {
              color: '#a87ceb'
            },
            value: 0,
            count: 0,
            name: 'Карта'
          },
        ]
      }
    ]
  })

  useEffect(() => {
    if (data.total) {
      let newData = [
        {
          itemStyle: {
            color: '#644593'
          },
          value: data.cod_total,
          count: data.cod,
          name: 'Наличные',
        },
        {
          itemStyle: {
            color: '#a87ceb'
          },
          value: data.card_total,
          count: data.card,
          name: 'Карта'
        },
      ]
      setOption({
        ...option,
        series: [{
          ...option.series,
          data: newData
        }]
      })
    }
  }, [data])

  return (
    <Card className={classes.orderDiagram}>
      <Grid className={classes.flexWrap}>
        <Grid className={classes.pie}>
          <ReactEcharts
            option={option}
            opts={{ renderer: 'svg' }}
          />
        </Grid>
        <Grid className={classes.pieStatistics}>
          <Card className={classes.statisticsMain}>
            <p className={classes.cardTitle}>Выручка: {currency(total || 0)}</p>
            <p className={classes.cardTitle}>Заказов сделано: {orders}</p>
            {
              weekdays ? <p className={classes.cardTitle}>{weekdays.actualDate}</p> : null
            }
          </Card>
          <Card className={classes.statistics}>
            <span>В прошлом месяце: </span>
            <p className={classes.cardTitle}>Выручка: {currency(monthAgoData.total || 0)}</p>
            <p className={classes.cardTitle}>Заказов сделано: {monthAgoData.orders}</p>
            {
              weekdays ? <p className={classes.cardTitle}>{weekdays.monthAgo}</p> : null
            }
          </Card>
          <Card className={classes.statistics}>
            <span>В позапрошлом месяце: </span>
            <p className={classes.cardTitle}>Выручка: {currency(twoMonthAgoData.total || 0)}</p>
            <p className={classes.cardTitle}>Заказов сделано: {twoMonthAgoData.orders}</p>
            {
              weekdays ? <p className={classes.cardTitle}>{weekdays.twoMonthsAgo}</p> : null
            }
          </Card>
        </Grid>
      </Grid>
    </Card >
  )
}

export default withStyles(styles)(FinanceDiagram)
