/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getUsers } from '../../../redux/actions/adminActions';
import UserTable from './UserTable';
import ReusedTabs from '../../../components/ReusedTabs';
//MUI
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

function createData(user) {
  return user;
}
const Staff = (props) => {
  const { admin: { users }, getUsers } = props;

  let rows = users ? users.map(user => {
    return createData(user)
  }) : [];
  let activeRow = rows.filter(user => user.status === 1)
  let unactiveRow = rows.filter(user => user.status === 0)
  const tabsInfo = [{
    component: <UserTable rows={activeRow} />,
    title: "Активные пользователи"
  },
  {
    component: <UserTable deleted={true} rows={unactiveRow} />,
    title: "Удаленные пользователи"
  }
  ]


  useEffect(() => {
    getUsers();
    document.title = 'Сотрудники'
  }, [])

  return (
    <Card>
      <CardContent>
        <CardHeader
          title="Все сотрудники:"
        />
        <Divider />
        <ReusedTabs tabsContent={tabsInfo} />
      </CardContent>
    </Card>
  )
}
const mapStateToProps = state => ({
  admin: state.admin
})
export default connect(mapStateToProps, { getUsers })(Staff)
