import React from 'react';
import { connect } from 'react-redux';
import { toggleSuccessPayment } from '../../../redux/actions/cartActions';
//MUI
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = theme => ({
  dialogWrap: {
    textAlign: 'center'
  },
  successButton: {
    padding: '10px 0',
    fontSize: '20px'
  }
})
const SuccessPay = (props) => {
  const { toggleSuccessPayment, cart, paymentOptions } = props;
  const { classes } = props;
  const newOrder = () => {
    toggleSuccessPayment(false);
    window.location.href = '/cash';
  }
  return (
    <Dialog className={classes.dialogWrap} open={cart.successPay}>
      <DialogTitle>{paymentOptions.is_paid === 1 ? 'Оплата произведена' : 'Заказ завершен'}</DialogTitle>
      <DialogActions>
        <Button onClick={() => newOrder()} className={classes.successButton} color="primary" fullWidth variant="contained" >
          К заказам
          </Button>
      </DialogActions>
    </Dialog>
  )
}
const mapStateToProps = state => ({
  cart: state.cart
})
export default connect(mapStateToProps, { toggleSuccessPayment })(withStyles(styles)(SuccessPay));
