/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PhoneBlackList from './PhoneBlackList';
import AddressBlackList from './AddressBlackList';
import ReusableTabs from 'components/ReusedTabs';
import Card from '@material-ui/core/Card';
import { useHttp } from 'hooks/httpHook';

//MUI 
import withStyles from '@material-ui/core/styles/withStyles';
//icons 
const styles = theme => ({
  ...theme.tables,
  tableTools: {
    display: 'flex',
    justifyContent: 'space-between'
  },
})

const BlackListPage = ({ classes }) => {
  const [phones, setPhones] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const { request, loading } = useHttp();

  const getPhones = async () => {
    if (!phones.length) {
      const data = await request('/blacklist', 'get');
      if (data) {
        setPhones(data);
      }
    }
  }

  const getAddresses = async () => {
    const data = await request('/blacklist/address', 'get');
    if (data) {
      setAddresses(data);
    }
  }

  useEffect(() => {
    document.title = 'Черный список'
    getPhones();
    getAddresses();
  }, []);

  const tabsInfo = [
    {
      component: <PhoneBlackList phones={phones} getPhones={getPhones} loading={loading} />,
      title: 'Номера'
    },
    {
      component: <AddressBlackList addresses={addresses} getAddresses={getAddresses} loading={loading} />,
      title: 'Адреса'
    }
  ]

  return (
    <Card>
      <ReusableTabs tabsContent={tabsInfo} />
    </Card>
  )
}

export default withStyles(styles)(BlackListPage);
