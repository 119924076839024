import React, {useEffect, useState} from "react";
import {useHttp}                    from 'hooks/httpHook';

import Dialog           from '@material-ui/core/Dialog';
import DialogContent    from '@material-ui/core/DialogContent';
import DialogTitle      from '@material-ui/core/DialogTitle';
import Typography       from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card             from "@material-ui/core/Card";
import withStyles       from "@material-ui/core/styles/withStyles";
import CardContent      from "@material-ui/core/CardContent";
import Grid             from "@material-ui/core/Grid";
import TableCell        from "@material-ui/core/TableCell";
import Table            from "@material-ui/core/Table";
import TableHead        from "@material-ui/core/TableHead";
import TableBody        from "@material-ui/core/TableBody";
import TableRow         from "@material-ui/core/TableRow";
import Toolbar          from "@material-ui/core/Toolbar";
import Button           from "@material-ui/core/Button";

const styles = theme => ({
  ...theme.mainStyles,
  button: {
    margin: '20px 0 0 0'
  },
  toolboxTitle: {
    flexGrow: 1,
    justifyContent: 'space-between'
  }
})

const CustomerWindow = ({classes, handleClose, open, item}) => {

  const {request, loading} = useHttp();
  const [info, setInfo]    = useState([]);
  const [order, setOrder] = useState('asc');
  const [check, setCheck] = useState(false)

  const date = (date) => {
    return new Date(date.replace(/-/g, "/")).toLocaleDateString('ru');
  }

  useEffect(() => {
    getData()
  }, [open])

  const getData = async () => {
    const data = await request('/admin/customers?telephone=' + item.telephone, 'get');
    setInfo(data);
  }

  function compare() {
    if ( order === 'asc' ){
      return -1;
    }
    if ( order === 'desc' ){
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    if (info.length) {
      setInfo([
        {
          ...info[0],
          orders: info[0].orders.sort(compare)
        }
      ])
    }
  }, [check])

  return (
    <>
      <Dialog
        maxWidth='md'
        fullWidth={true}
        open={open}
        onClose={() => {
          handleClose();
        }}
      >
        <DialogTitle>
          <Typography component={'p'} variant="h6">Имя: {item.first_name || 'Отсутствует'}</Typography>
          <Typography component={'p'} variant="subtitle1">Телефон: {item.telephone}</Typography>
        </DialogTitle>
        <DialogContent>
          {loading ? <CircularProgress /> : (
            <React.Fragment>
              {info.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <Typography component={'div'} variant="h6">Общая информация</Typography>
                    <Grid container spacing={2}>
                      {i.coupons.map((coupon, index) => {
                        return (
                          <Grid item xs key={index}>
                            <Card>
                              <CardContent>
                                <Typography component={'div'} variant="subtitle2">Купон: {coupon.coupon || "-"}</Typography>
                                <Typography component={'div'} variant="subtitle2">Применялся: {coupon.quantity}</Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        )
                      })}
                    </Grid>

                    <Toolbar component="div" className={classes.toolboxTitle} disableGutters={true}>
                      <Typography component={'div'} variant="h6">Заказы</Typography>
                        <Button color="primary" variant="contained" onClick={() => setCheck(prev => !prev)}>{check ? 'от новых к старым' : 'от старых к новым'}</Button>
                    </Toolbar>
                    {i.orders.map((order, index) => {
                      return (
                        <Card key={index} className={classes.button}>
                          <CardContent>
                            <div className={classes.flexbox}>
                              <Typography component={'span'} variant="body2">ID: {order.id}</Typography>
                              <Typography component={'span'} variant="body2">Купон: {order.coupon || "-"}</Typography>
                              <Typography component={'span'} variant="body2">Сумма: {order.total}</Typography>
                              <Typography component={'span'} variant="body2">Способ получения: {order.shipping_method === 'take_away' ? <Typography component={'span'} style={{fontWeight: 'bold'}}>Самовывоз</Typography> : 'Доставка' }</Typography>
                              <Typography component={'span'} variant="body2">Дата: {date(order.created_at)}</Typography>
                            </div>
                            <Toolbar component="div" disableGutters={true}>
                              <Typography variant="h6" component="div">
                                Товары
                              </Typography>
                            </Toolbar>
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Название</TableCell>
                                  <TableCell align="center">Название опции</TableCell>
                                  <TableCell align="center">Значение опции</TableCell>
                                  <TableCell align="center">Цена</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {order.products.map((product, index) => {
                                  return (
                                    <TableRow key={index} hover>
                                      <TableCell>
                                        <Typography component={'div'} variant="body2">{product.name}</Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography component={'div'} variant="body2">{product.options.length ? product.options[0].name : "-"}</Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography component={'div'} variant="body2">{product.options.length ? product.options[0].value : "-"}</Typography>
                                      </TableCell>
                                      <TableCell align="center">
                                        <Typography component={'div'} variant="body2">{product.price}</Typography>
                                      </TableCell>
                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                          </CardContent>
                        </Card>
                      )
                    })}
                  </React.Fragment>
                )
              })}
            </React.Fragment>
          )
          }
        </DialogContent>

      </Dialog>
    </>
  )
}

export default withStyles(styles)(CustomerWindow)