/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ReactEcharts from 'echarts-for-react';
import echarts from 'echarts';
import { useTime } from 'hooks/timeHook';
const CouponQuantityDiagram = ({ data }) => {
  const { formatDate } = useTime();
  const [x, setX] = useState([]);
  const [y, setY] = useState([]);

  let option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: x
    },
    yAxis: {
      type: 'value'
    },
    series: [{
      data: y,
      type: 'line',
      areaStyle: {},
      itemStyle: {
        color: new echarts.graphic.LinearGradient(
          0, 0, 0, 1,
          [
            { offset: 0, color: '#bd9de0' },
            { offset: 0.5, color: '#af93cf' },
            { offset: 1, color: '#cfbce6' }
          ]
        )
      },
    }]
  };

  useEffect(() => {
    if (data) {
      let xArr = data.map((item) => {
        let date = formatDate(item.date, 'D MMMM')
        return date;
      });
      let yArr = data.map((item) => item.quantity);
      setX(xArr);
      setY(yArr)
    }
  }, [data])


  return (
    <ReactEcharts
    style={{height: '100%'}}
      option={option}
      opts={{ renderer: 'svg' }}
    />
  )
}

export default CouponQuantityDiagram
