/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import EyeInputField from '../../components/EyeInputField';
import PropTypes from 'prop-types'
import Logo from 'img/logo.png'
import { connect } from 'react-redux';
//MUI 
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { loginUser } from '../../redux/actions/userActions';

const styles = (theme) => ({
  ...theme.mainStyles,
  formWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 45,
    width: '500px',
    background: 'white',
    boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.2)'
  },
  formTitle: {
    fontWeight: '400',
    fontSize: '1.5rem',
    padding: '10px 0 40px 0'
  },
  formLabel: {
    paddingBottom: '15px',
  },
  iconShow: {
    position: 'absolute',
    top: '40%',
    transform: 'translateY(-50%)',
    right: 0,
    zIndex: 100
  },
  inputWrap: {
    position: 'relative'
  },
  logo: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
})
const Login = (props) => {
  const { classes, UI: { loading, errors } } = props;
  const [state, setState] = useState({
    email: '',
    password: '',
    errors: {},
  })

  useEffect(() => {
    document.title = 'Вход в систему'
  }, [])

  useEffect(() => {
    if (errors) {
      setState({
        ...state,
        errors
      })
    }
  }, [errors])

  const handleSubmit = event => {
    event.preventDefault()

    let userData = {
      email: state.email,
      password: state.password,
    }
    props.loginUser(userData, props.history);
  }
  const handleChange = e => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }
  return (
    <div className={classes.formWrapper}>
      <img className={classes.logo} src={Logo} alt="" />
      <h1 className={classes.formTitle}>Вход в CRM</h1>
      <form noValidate onSubmit={handleSubmit}>
        <TextField
          id="email"
          name="email"
          type="email"
          label="Логин"
          value={state.email}
          onChange={handleChange}
          className={classes.formLabel}
          error={state.errors.message ? true : false}
          fullWidth
          variant="outlined"
        />
        <EyeInputField configs={{
          value: state.password,
          onChange: handleChange,
          helperText: state.errors.error,
          error: state.errors.message ? true : false,
          className: classes.formLabel,
          name: 'password',
          variant: 'outlined',
          label: 'Пароль'
        }} />
        <Button
          variant="contained"
          type="submit"
          color="primary"
          className={classes.button}
          disabled={loading}
          fullWidth
        >Войти
        {loading && (
            <CircularProgress color="secondary" size={30} className={classes.progress} />
          )}</Button>
      </form>
    </div>
  )
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired
}
const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
})
const mapActionToProps = {
  loginUser
}

export default connect(mapStateToProps, mapActionToProps)(withStyles(styles)(Login));
