import React, { useState, useEffect } from 'react';
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import EventIcon from '@material-ui/icons/Event';
import { useTime } from 'hooks/timeHook';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { date } from 'yup';


const styles = theme => ({
  datePicker: {
    display: 'block',
    marginRight: '30px'
  },
  dateWrap: {
    display: 'flex'
  }
})


const AnalyticsSchedule = ({ flexWrap = true, classes, dateState, setDateState, dateStart, dateEnd }) => {
  const [startDate, setStartDate] = useState(dateState.date_start ? dateState.date_start : null);
  const { formatDate } = useTime();
  const [finishDate, setFinishDate] = useState(dateState.date_end ? dateState.date_end : null);
  const startDateChange = (date) => {
    let formatedDate = formatDate(date, "YYYY-MM-DD")
    setDateState({
      ...dateState,
      date_start: formatedDate
    })
    setStartDate(date);
  };

  useEffect(() => {
    if (dateState.date_start && dateState.date_end) {
      setStartDate(dateState.date_start);
      setFinishDate(dateState.date_end);
    }
  }, [dateState])

  useEffect(() => {
    if (dateStart && dateEnd) {
      setStartDate(dateStart)
      setFinishDate(dateEnd);
    }
  }, [dateStart, dateEnd])

  const finishDateChange = date => {
    let formatedDate = formatDate(date, "YYYY-MM-DD");
    setDateState({
      ...dateState,
      date_end: formatedDate
    })
    setFinishDate(date);
  };

  return (
    <MuiPickersUtilsProvider locale={ruLocale} utils={DateFnsUtils}>
      <Grid className={flexWrap ? classes.dateWrap : ''}>
        <KeyboardDatePicker
          autoOk={true}
          keyboardIcon={<EventIcon color="primary" />}
          variant="inline"
          name="date_start"
          format="dd/MM/yyyy"
          margin="normal"
          className={classes.datePicker}
          label="Начальная дата"
          value={startDate}
          onChange={startDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        <KeyboardDatePicker
          variant="inline"
          keyboardIcon={<EventIcon color="primary" />}
          name="date_end"
          autoOk={true}
          className={classes.datePicker}
          format="dd/MM/yyyy"
          margin="normal"
          label="Конечная дата"
          value={finishDate}
          onChange={finishDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default withStyles(styles)(AnalyticsSchedule)
