import React, { useState } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import withStyles from '@material-ui/core/styles/withStyles';
import { useTime } from 'hooks/timeHook';

const styles = theme => ({
  formControl: {
    minWidth: 120,
    width: '48%'
  },
  selectWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    border: '1px solid grey',
    borderRadius: '5px'
  },
  select: {
    marginBottom: theme.spacing(1),
  },
  addedList: {
    height: '200px'
  }
})

const WriteOffSelectIngredient = ({ products, category, classes, updateList, addedList, currentDate }) => {
  const [value, setValue] = useState('');
  const { formatDate } = useTime();

  const handleChange = (e) => {
    const { value } = e.target;
    setValue(value);
    let targetProduct = products.find((item) => item.name === value);
    setValue('');

    let product = addedList.filter(i => i.name === value);
    let arr = addedList.filter(a => a.name !== value);

    if (product.length) {
      product[0].quantity++
      arr = [...arr, product[0]]

      updateList(arr)
    } else {

      targetProduct = {
        created_at : formatDate(currentDate, "YYYY-MM-DD"),
        name      : targetProduct.name,
        ingredient_id: targetProduct.id,
        weight     : 1,
      }

      updateList(prev => {
        return [
          ...prev,
          targetProduct
        ]
      })
    }
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel htmlFor="categoryName">Ингредиенты</InputLabel>
      <Select value={value} onChange={handleChange} id="categoryName">
        {
          category.map((item) => {
            return <MenuItem key={item.name} value={item.name}>{item.name}</MenuItem>
          })
        }
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(WriteOffSelectIngredient)