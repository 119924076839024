import React from 'react';
import {
  Switch, useRouteMatch
} from 'react-router-dom';
import AuthRoute from 'components/AuthRoute';
import AnalyticsPage from './AnalyticsPage';
import StaffPage from './StaffPage';
import CouponsPage  from './CouponsPage';
import WriteOffPage from './WriteOffPage';
import DataPage     from './DataPage';
import BlackListPage from './BlackListPage';
import IngredientsPage from './IngredientsPage';
import CustomerPage from './CustomersPage';
import AdminNavbar from '../../components/Navbar/AdminNavbar';
import { makeStyles } from '@material-ui/core/styles';
import NoMatch from 'pages/NoMatch';
//Icons
import FastfoodIcon from '@material-ui/icons/Fastfood';
import Personicon from '@material-ui/icons/Person';
import TimelineIcon from '@material-ui/icons/Timeline';
import StorageIcon from '@material-ui/icons/Storage';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import BlackListIcon from '@material-ui/icons/PersonAddDisabled';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Admin = (props) => {
  let { path, url } = useRouteMatch();
  const links = [
    {
      name: 'Сотрудники',
      icon: <Personicon />,
      path: `${url}/staff`
    },
    {
      name: 'Черный список',
      icon: <BlackListIcon />,
      path: `${url}/blacklist`
    },
    {
      name: 'Аналитика',
      icon: <TimelineIcon />,
      path: `${url}/analytics`
    },
    {
      name: 'Промокоды',
      icon: <LocalOfferIcon />,
      path: `${url}/coupons`
    },
    {
      name: 'Продукты',
      icon: <FastfoodIcon />,
      path: `${url}/ingredients`
    },
    {
      name: 'База данных',
      icon: <StorageIcon />,
      path: `${url}/database`
    },
    {
      name: 'Списание',
      icon: <AssignmentReturnIcon />,
      path: `${url}/writeoff`
    },
    {
      name: 'Клиенты',
      icon: <PeopleAltIcon />,
      path: `${url}/customers`,
    }
  ]
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AdminNavbar url={url} links={links} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Switch>
          <AuthRoute path={`${path}/blacklist`}>
            <BlackListPage />
          </AuthRoute>
          <AuthRoute exact path={path}>
            <p></p>
          </AuthRoute>
          <AuthRoute path={`${path}/ingredients`}>
            <IngredientsPage />
          </AuthRoute>
          <AuthRoute
            path={`${path}/staff`}>
            <StaffPage />
          </AuthRoute>
          <AuthRoute
            path={`${path}/analytics`}>
            <AnalyticsPage />
          </AuthRoute>
          <AuthRoute
            path={`${path}/coupons`}>
            <CouponsPage />
          </AuthRoute>
          <AuthRoute
            path={`${path}/database`}>
            <DataPage />
          </AuthRoute>
          <AuthRoute
            path={`${path}/writeoff`}>
            <WriteOffPage />
          </AuthRoute>
          <AuthRoute
            path={`${path}/customers`}>
            <CustomerPage />
          </AuthRoute>
          <AuthRoute
            path='*'
            component={NoMatch} />
        </Switch>
      </main>
    </div>
  );
}

export default Admin;