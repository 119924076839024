import React, { useState, useEffect } from 'react'
import axios from 'axios';
//MUI
import DoneIcon from '@material-ui/icons/Done';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
const styles = theme => ({
  orderItem: {
    cursor: 'pointer',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    fontWeight: '600',
    background: '#f7f9ff',
    transition: '0.5',
    boxShadow: '0 2px 2px rgba(0, 0, 0, 0.4)'
  },
  orderTakeAway: {
    background: theme.palette.primary.main,
    color: 'white'
  },
  orderDelivery: {
    background: theme.palette.secondary.main,
    color: 'white'
  },
  orderTakeAwaySushi: {
    background: '#445521',
    color: 'white'
  },
  orderDeliverySushi: {
    background: '#f3dab7',
    color: 'white'
  },
  orderFinish: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    zIndex: '100',
    color: 'white'
  },
  buttonsWrap: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})
const CashOrder = ({ openOrderInfo, setOrderInfo, data, done, classes, hold }) => {
  const [open, setOpen] = useState(false);
  const [orderClass, setOrderClass] = useState('');
  const finishOrder = () => {
    axios.get(`/orders/close/${data.id}`)
      .then(() => {
        setOrderInfo({})
      })
      .catch(err => console.log(err))
  }
  useEffect(() => {
    if (data) {
      let orderStyle = '';
      data.products.map(item => {
        if ((item.category.category_id === 7 || item.info.susi_combo) && data.shipping_method === 'delivery') {
          return orderStyle = `${classes.orderItem} ${classes.orderDeliverySushi}`
        } else if ((item.category.category_id === 7 || item.info.susi_combo) && data.shipping_method === 'take_away') {
          return orderStyle = `${classes.orderItem} ${classes.orderTakeAwaySushi}`
        }
      })

      if (orderStyle === '') {
        if (data.shipping_method === 'delivery') {
          orderStyle = `${classes.orderItem} ${classes.orderDelivery}`
        } else {
          orderStyle = `${classes.orderItem} ${classes.orderTakeAway}`
        }
    }
      setOrderClass(orderStyle)
    }
  }, [data])

  const finishOrderButton = done ? (
    <>
      <Card onClick={finishOrder} className={`${orderClass} ${classes.orderFinish}`}>
        <DoneIcon />
      </Card>
    </>
  ) : null;
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Card onClick={() => { setOpen(true); openOrderInfo(data) }} className={orderClass}>
        {open ? finishOrderButton : null}
        {data.invoice_no}
      </Card>
    </ClickAwayListener>

  )
}

export default withStyles(styles)(CashOrder)
