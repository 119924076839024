import React from 'react'
import PropTypes from 'prop-types'
import Order from '../Order'
const propTypes = {
    orders: PropTypes.array,
    stage: PropTypes.string,
}
const KitchenOrders = ({ orders, stage }) => {
    return orders.map((item, i) => <Order data={item} stage={stage} key={i} />)
}

KitchenOrders.propTypes = propTypes

export default KitchenOrders
