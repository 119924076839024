/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import PizzaIngredientItem from '../PizzaIngredientItem';
import { Context } from 'hooks/contextHook';
import { Scrollbars } from 'react-custom-scrollbars';
import IngredientEdit from '../IngredientEdit'
//MUI 
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHttp } from 'hooks/httpHook';
import Snackbar from '@material-ui/core/Snackbar';


const styles = theme => ({
  ingredientsList: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  productOption: {
    display: 'flex',
    width: '32%',
    flexDirection: 'column',
    paddingBottom: '1rem',
    marginRight: '2%',
    '&:nth-child(3n+3)': {
      marginRight: 0,
    }
  },
  loadingOption: {
    width: '32%',
    marginBottom: '1rem',
    marginRight: '2%',
    '&:nth-child(3n+3)': {
      marginRight: 0,
    }
  },
  productOptionWrap: {
    minWidth: '500px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  dialogTitle: {
    paddingBottom: 0
  },
  selectInput: {
    width: '50%'
  },
  productInfo: {
    width: '55%',
  },
  ingredientEdit: {
    width: '44%',
  }
});

export const ProductWindow = ({ loading, products, classes, getData, item, handleClose, open }) => {
  const [product, setProduct] = useState({});
  const [ingredientValue, setIngredientValue] = useState('');
  const [active, setActive] = useState({});
  const [ingredients, setIngredients] = useState([]);
  const { request, error, success, clearError } = useHttp();
  const { updated } = useContext(Context);

  const getIngredients = () => {
    axios.get('/admin/ingredients')
      .then(res => {
        setIngredients(res.data);
      })
  }
  useEffect(() => {
    getIngredients();
  }, [item])

  useEffect(() => {
    if (updated) {
      getIngredients();
    }
  }, [updated])

  useEffect(() => {
    setProduct(item);
  }, [item])

  const addIngredientToServer = async (ingredientId) => {
    clearError();
    await request(`/admin/products/${product.id}/ingredients`, 'post', { ingredient_id: ingredientId });
  }

  const changeIngredientOnServer = async (data) => {
    clearError();
    await request(`/admin/products/${product.id}/ingredients`, 'put', data);
  }

  const removeIngredient = async (data) => {
    let newIngredients = product.ingredients.filter((item) => item.id !== data.id);
    clearError();
    setProduct({
      ...product,
      ingredients: newIngredients
    });
    await request(`/admin/products/${product.id}/ingredients/${data.id}`, 'delete');
  }

  const addNewIngredient = async (e) => {
    const { value } = e.target;
    setIngredientValue(value);
    const target = ingredients.find(a => a.name === value);
    let newIngredient = {
      ...target,
      is_global: 1,
    }

    if (!product.options[0].dough_type) {
      newIngredient.options = [];
      newIngredient.is_global = 0;
    }

    let newData = {
      ...product,
      ingredients: [
        ...product.ingredients,
        newIngredient
      ]
    }

    await addIngredientToServer(newIngredient.id);
    setProduct(newData);
  }

  useEffect(() => {
    if (products.length) {
      let targetProduct = products.find(item => item.id === product.id);
      if (targetProduct) {
        setProduct(targetProduct);
      }
    }
  }, [products])

  useEffect(() => {
    if (success) {
      getData();
      let itemIsRemoved = product.ingredients.find(item => item.name === active.name);
      if (!itemIsRemoved) {
        setActive({})
      }
    }
  }, [success])

  return (
    <>
      <Dialog
        maxWidth='md'
        fullWidth={true}
        open={open}
        onClose={() => {
          clearError();
          handleClose();
          setActive({})
        }}
      >
        <DialogTitle className={classes.dialogTitle}>Продукт: {product.name}</DialogTitle>
        <form>
          <DialogContent style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Grid className={classes.productInfo}>
              <FormControl className={classes.selectInput}>
                <InputLabel id="demo-mutiple-name-label">Выберите ингредиент</InputLabel>
                <Select
                  value={ingredientValue}
                  onChange={(e) => addNewIngredient(e)}
                >
                  {
                    ingredients.sort(function (a, b) {
                      let nameA = a.name.toLowerCase(), nameB = b.name.toLowerCase();
                      return nameA < nameB ? -1 : 1;
                    }).map((item) => {
                      let disabled = false;
                      let alreadyInList = product.ingredients ? product.ingredients.findIndex(a => a.name === item.name) : null;
                      if (alreadyInList >= 0) {
                        disabled = true;
                      }
                      return (
                        <MenuItem disabled={disabled} key={item.name} value={item.name}>
                          {item.name}
                        </MenuItem>
                      )
                    })
                  }
                </Select>
              </FormControl>
              <Scrollbars style={{ height: '350px' }}>
                <Grid className={classes.ingredientsList}>
                  {
                    product.ingredients ? product.ingredients.map((ingredient, index) => {
                      return <PizzaIngredientItem
                        key={index}
                        active={active}
                        setActive={setActive}
                        item={ingredient}
                        ingredients={ingredients}
                        changeIngredientOnServer={changeIngredientOnServer}
                        loading={loading}
                        removeIngredient={removeIngredient}
                      />
                    }) : null
                  }
                </Grid>
              </Scrollbars>

              <Grid className={classes.productOptionWrap}>
                {
                  !loading ? product.options ? product.options.map((item, index) => {
                    return (
                      (
                        <Grid key={index} className={classes.productOption}>
                          <p>{item.dough_size} - {item.dough_type}</p>
                          <p>Вес: <span style={{ fontWeight: 'bold' }}>{item.weight}г.</span></p>
                          <p>Цена: <span style={{ fontWeight: 'bold' }}>{item.price}руб.</span></p>
                        </Grid>
                      )
                    )
                  }) : null :
                    Array.from({ length: 5 }).map((item, index) => (
                      <Grid key={index} className={classes.loadingOption}>
                        <CircularProgress size={51} key={index} />
                      </Grid>
                    ))
                }
              </Grid>
            </Grid>
            <Grid className={classes.ingredientEdit}>
              <IngredientEdit
                product={product}
                item={active}
                ingredients={ingredients}
                loading={loading}
                changeIngredientOnServer={changeIngredientOnServer}
                removeIngredient={removeIngredient}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
          </DialogActions>
        </form>
        <Snackbar
          open={!!error}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            severity="error">Произошла ошибка, попробуйте позже</Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert
            variant="filled"
            severity="success">Продукт успешно изменен</Alert>
        </Snackbar>
      </Dialog>

    </>
  );
}

export default withStyles(styles)(ProductWindow)
