import React, { useEffect, useRef, useState } from "react";
import CustomerBaseTable from "./CustomerBaseTable";
import axios from "axios";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { connect } from "react-redux";
import { getCustomers } from "../../../redux/actions/adminActions";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableBody from "@material-ui/core/TableBody";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button            from "@material-ui/core/Button";
import ButtonGroup       from "@material-ui/core/ButtonGroup";
import Grid              from "@material-ui/core/Grid";
import Popper            from "@material-ui/core/Popper";
import Grow              from "@material-ui/core/Grow";
import Paper             from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import MenuList          from "@material-ui/core/MenuList";
import MenuItem          from "@material-ui/core/MenuItem";
import currency          from "../../../util/currency";
import TablePagination   from "@material-ui/core/TablePagination";
import {TextField}       from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import Fade from '@material-ui/core/Fade';

const CustomerPage = ({ admin: { customersBase }, getCustomers }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("first_name");
  const [customerType, setCustomerType] = useState("all");
  const [coupons, setCoupons] = useState([]);
  const [useCoupon, setUseCoupon] = useState("");
  const [open, setOpen] = useState(false);
  const [openMonth, setOpenMonth] = useState(false);
  const [openClient, setOpenClient] = useState(false);
  const anchorRef = useRef(null);
  const anchorRefMonth = useRef(null);
  const anchorRefClient = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedIndexMonth, setSelectedIndexMonth] = useState(100);
  const [selectedIndexClient, setSelectedIndexClient] = useState(200);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [checked, setChecked] = useState(false);

  const headCells = [
    { id: "quantity", label: "Заказов за всё время" },
    { id: "last_order", label: "Последний заказ" },
  ];

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    axios
      .get("/coupons?filter=p")
      .then((res) => {
        setCoupons(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getCouponUsers = (coupon) => {
    getCustomers({ coupon });
    setUseCoupon(coupon);
  };

  const getCustomersCount = (type) => {
    switch (type) {
      case "all":
        return customersBase[0].all.length;
      case "less_one_month":
        return customersBase[0].less_one_month.length;
      case "less_two_month":
        return customersBase[0].less_two_month.length;
      case "less_three_month":
        return customersBase[0].less_three_month.length;
      case "in_month":
        return customersBase[0].in_month.length;
      case "one_all":
        return customersBase[0].one_all.length;
      case "one_less_one_month":
        return customersBase[0].one_less_one_month.length;
      case "one_less_two_month":
        return customersBase[0].one_less_two_month.length;
      case "one_less_three_month":
        return customersBase[0].one_less_three_month.length;
      case "one_in_month":
        return customersBase[0].one_in_month.length;
      case "medium_all":
        return customersBase[0].medium_all.length;
      case "medium_less_one_month":
        return customersBase[0].medium_less_one_month.length;
      case "medium_less_two_month":
        return customersBase[0].medium_less_two_month.length;
      case "medium_less_three_month":
        return customersBase[0].medium_less_three_month.length;
      case "medium_in_month":
        return customersBase[0].medium_in_month.length;
      case "max_all":
        return customersBase[0].max_all.length;
      case "max_less_one_month":
        return customersBase[0].max_less_one_month.length;
      case "max_less_two_month":
        return customersBase[0].max_less_two_month.length;
      case "max_less_three_month":
        return customersBase[0].max_less_three_month.length;
      case "max_in_month":
        return customersBase[0].max_in_month.length;
    }
  };

  const getFile = (type) => {
    let url =
      useCoupon.length > 0
        ? "coupon=" +
          useCoupon +
          "&clients=" +
          customerType +
          "&file_type=" +
          type
        : "clients=" + customerType + "&file_type=" + type;
    axios({
      method: "GET",
      url: "/admin/customers/export?" + url,
      responseType: "blob",
    }).then((response) => {
      const options =
        response.data.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? {
              type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
            }
          : { type: "application/vnd.ms-excel;charset=utf-8" };
      const endFile =
        response.data.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ? ".xlsx"
          : ".csv";
      const blob = new Blob([response.data], options);
      const aEle = document.createElement("a");
      const href = window.URL.createObjectURL(blob);
      aEle.href = href;
      aEle.download =
        "clients_" +
        (useCoupon.length > 0
          ? "coupon_" + useCoupon + "_clients_" + customerType
          : "clients_" + customerType) +
        endFile;
      document.body.appendChild(aEle);
      aEle.click();
      document.body.removeChild(aEle);
      window.URL.revokeObjectURL(href);
    });
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleMenuItemClickMonth = (event, index) => {
    setSelectedIndexMonth(index);
    setOpenMonth(false);
  };

  const handleMenuItemClickClient = (event, index) => {
    setSelectedIndexClient(index);
    setOpenClient(false);
  };

  const handleClosedMonth = (event) => {
    if (
      anchorRefMonth.current &&
      anchorRefMonth.current.contains(event.target)
    ) {
      return;
    }

    setOpenMonth(false);
  };

  const handleClosedClient = (event) => {
    if (
      anchorRefClient.current &&
      anchorRefClient.current.contains(event.target)
    ) {
      return;
    }

    setOpenClient(false);
  };

  const handleToggleMonth = () => {
    setOpenMonth((prevOpenMonth) => !prevOpenMonth);
  };

  const handleToggleClient = () => {
    setOpenClient((prevOpenClient) => !prevOpenClient);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const customers = () => {
    const data = customersBase.map((item) => {
      return item[customerType];
    });

    return data[0];
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Toolbar
            disableGutters={true}
            style={{ justifyContent: "space-between" }}
          >
            <Grid container spacing={3} style={{ alignItems: "center" }}>
              <Grid item sm={2} md={1} lg={1} style={{ whiteSpace: "nowrap" }}>
                <Button variant="outlined" onClick={handleChange}><SearchIcon /></Button>
                <Fade in={checked} style={{position: 'absolute', zIndex: '9', marginTop: '10px'}}>
                  <Paper elevation={4}>
                    <TextField multiline variant="outlined" label="Поиск номера" type="text" defaultValue="" color="primary" placeholder="Номер без +" onChange={e => setSearch(e.target.value)} />
                  </Paper>
                </Fade>
              </Grid>
              <Grid item sm md lg style={{ whiteSpace: "nowrap" }}>
                <Typography variant="h5" component={"div"}>
                  Клиентов: {getCustomersCount(customerType)}
                </Typography>
              </Grid>
              <Grid item sm md lg style={{ whiteSpace: "nowrap" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography component={"div"} variant="h6">
                    Купон:{" "}
                  </Typography>
                  <Button
                    color="primary"
                    variant={useCoupon.length > 0 ? "outlined" : "contained"}
                    size="small"
                    aria-label="select merge strategy"
                    aria-haspopup="menu"
                    ref={anchorRef}
                    onClick={handleToggle}
                  >
                    {useCoupon.length > 0 ? useCoupon : "Выбрать купон"}
                  </Button>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    style={{ zIndex: 1 }}
                    transition
                    disablePortal
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList id="split-button-menu">
                              {coupons.length
                                ? coupons.map((item, index) => (
                                    <MenuItem
                                      selected={index === selectedIndex}
                                      key={index}
                                      onClick={(event) => {
                                        if (useCoupon === item.code) {
                                          setCustomerType("all");
                                          getCustomers();
                                          setUseCoupon("");
                                        } else {
                                          getCouponUsers(item.code);
                                          setUseCoupon(item.code);
                                        }

                                        handleMenuItemClick(event, index);
                                      }}
                                    >
                                      {item.code}
                                    </MenuItem>
                                  ))
                                : null}
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Grid>
              <Grid item sm md lg style={{ whiteSpace: "nowrap" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography component={"div"} variant="h6">
                    Заказы:
                  </Typography>
                  <Button
                    color="primary"
                    variant={"outlined"}
                    size="small"
                    aria-label="select1 merge1 strategy1"
                    aria-haspopup="menu1"
                    ref={anchorRefMonth}
                    onClick={handleToggleMonth}
                  >
                    {customerType.indexOf("all") !== -1
                      ? "За все время"
                      : customerType.indexOf("less_one_month") !== -1
                      ? "Больше месяца"
                      : customerType.indexOf("less_two_month") !== -1
                      ? "Больше 2 месяцев"
                      : customerType.indexOf("less_three_month") !== -1
                      ? "Больше 3 месяцев"
                      : customerType.indexOf("in_month") !== -1
                      ? "В этом месяце"
                      : ""}
                  </Button>
                  <Popper
                    open={openMonth}
                    anchorEl={anchorRefMonth.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 1 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClosedMonth}>
                            <MenuList id="split-button-menu1">
                              <MenuItem
                                selected={100 === selectedIndexMonth}
                                onClick={(event) => {
                                  if (customerType.indexOf("medium") !== -1) {
                                    setCustomerType("medium_all");
                                  } else if (
                                    customerType.indexOf("max") !== -1
                                  ) {
                                    setCustomerType("max_all");
                                  } else if (
                                    customerType.indexOf("one") !== -1
                                  ) {
                                    setCustomerType("one_all");
                                  } else {
                                    setCustomerType("all");
                                  }

                                  handleMenuItemClickMonth(event, 100);
                                }}
                              >
                                За все время
                              </MenuItem>
                              <MenuItem
                                selected={101 === selectedIndexMonth}
                                onClick={(event) => {
                                  if (customerType.indexOf("medium") !== -1) {
                                    setCustomerType("medium_less_one_month");
                                  } else if (
                                    customerType.indexOf("max") !== -1
                                  ) {
                                    setCustomerType("max_less_one_month");
                                  } else if (
                                    customerType.indexOf("one") !== -1
                                  ) {
                                    setCustomerType("one_less_one_month");
                                  } else {
                                    setCustomerType("less_one_month");
                                  }

                                  handleMenuItemClickMonth(event, 101);
                                }}
                              >
                                Больше месяца
                              </MenuItem>
                              <MenuItem
                                selected={104 === selectedIndexMonth}
                                onClick={(event) => {
                                  if (customerType.indexOf("medium") !== -1) {
                                    setCustomerType("medium_less_two_month");
                                  } else if (
                                    customerType.indexOf("max") !== -1
                                  ) {
                                    setCustomerType("max_less_two_month");
                                  } else if (
                                    customerType.indexOf("one") !== -1
                                  ) {
                                    setCustomerType("one_less_two_month");
                                  } else {
                                    setCustomerType("less_two_month");
                                  }

                                  handleMenuItemClickMonth(event, 104);
                                }}
                              >
                                Больше 2 месяцев
                              </MenuItem>
                              <MenuItem
                                selected={105 === selectedIndexMonth}
                                onClick={(event) => {
                                  if (customerType.indexOf("medium") !== -1) {
                                    setCustomerType("medium_less_three_month");
                                  } else if (
                                    customerType.indexOf("max") !== -1
                                  ) {
                                    setCustomerType("max_less_three_month");
                                  } else if (
                                    customerType.indexOf("one") !== -1
                                  ) {
                                    setCustomerType("one_less_three_month");
                                  } else {
                                    setCustomerType("less_three_month");
                                  }

                                  handleMenuItemClickMonth(event, 105);
                                }}
                              >
                                Больше 3 месяцев
                              </MenuItem>
                              <MenuItem
                                selected={102 === selectedIndexMonth}
                                onClick={(event) => {
                                  if (customerType.indexOf("medium") !== -1) {
                                    setCustomerType("medium_in_month");
                                  } else if (
                                    customerType.indexOf("max") !== -1
                                  ) {
                                    setCustomerType("max_in_month");
                                  } else if (
                                    customerType.indexOf("one") !== -1
                                  ) {
                                    setCustomerType("one_in_month");
                                  } else {
                                    setCustomerType("in_month");
                                  }

                                  handleMenuItemClickMonth(event, 102);
                                }}
                              >
                                В этом месяце
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Grid>
              <Grid item sm md lg style={{ whiteSpace: "nowrap" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography component={"div"} variant="h6">
                    Тип клиентов:
                  </Typography>
                  <Button
                    color="primary"
                    variant={"outlined"}
                    size="small"
                    aria-label="select2 merge2 strategy2"
                    aria-haspopup="menu2"
                    ref={anchorRefClient}
                    onClick={handleToggleClient}
                  >
                    {customerType.indexOf("all") === 0 ||
                    customerType.indexOf("in_month") === 0 ||
                    customerType.indexOf("less_one_month") === 0 ||
                    customerType.indexOf("less_two_month") === 0 ||
                    customerType.indexOf("less_three_month") === 0
                      ? "Все"
                      : customerType.indexOf("one") === 0
                      ? "Один заказ"
                      : customerType.indexOf("medium") === 0
                      ? "Постоянные"
                      : customerType.indexOf("max") === 0
                      ? "Фанаты"
                      : ""}
                  </Button>
                  <Popper
                    open={openClient}
                    anchorEl={anchorRefClient.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: 1 }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom",
                        }}
                      >
                        <Paper>
                          <ClickAwayListener onClickAway={handleClosedClient}>
                            <MenuList id="split-button-menu2">
                              <MenuItem
                                selected={200 === selectedIndexClient}
                                onClick={(event) => {
                                  setCustomerType("all");
                                  handleMenuItemClickClient(event, 200);
                                  handleMenuItemClickMonth(event, 100);
                                }}
                              >
                                Все
                              </MenuItem>
                              <MenuItem
                                selected={201 === selectedIndexClient}
                                onClick={(event) => {
                                  setCustomerType("one_all");
                                  handleMenuItemClickClient(event, 201);
                                  handleMenuItemClickMonth(event, 100);
                                }}
                              >
                                Один заказ
                              </MenuItem>
                              <MenuItem
                                selected={202 === selectedIndexClient}
                                onClick={(event) => {
                                  setCustomerType("medium_all");
                                  handleMenuItemClickClient(event, 202);
                                  handleMenuItemClickMonth(event, 100);
                                }}
                              >
                                Постоянные
                              </MenuItem>
                              <MenuItem
                                selected={203 === selectedIndexClient}
                                onClick={(event) => {
                                  setCustomerType("max_all");
                                  handleMenuItemClickClient(event, 203);
                                  handleMenuItemClickMonth(event, 100);
                                }}
                              >
                                Фанаты
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </div>
              </Grid>
              <Grid item sm md lg style={{ whiteSpace: "nowrap" }}>
                <ButtonGroup>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => getFile("xlsx")}
                  >
                    Выгрузить в Excel
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => getFile("csv")}
                  >
                    Выгрузить в CSV
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
          </Toolbar>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Имя</TableCell>
                <TableCell align={"right"}>Телефон</TableCell>
                {headCells.map((item) => (
                  <TableCell
                    align="right"
                    key={item.id}
                    sortDirection={orderBy === item.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === item.id}
                      direction={orderBy === item.id ? order : "asc"}
                      onClick={createSortHandler(item.id)}
                    >
                      {item.label}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            <CustomerBaseTable
              page={page}
              rowsPerPage={rowsPerPage}
              order={order}
              customerType={customerType}
              orderBy={orderBy}
              customersBase={customersBase}
              getCustomers={getCustomers}
              search={search}
            />
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={customers().length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage="Элементов на странице"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} из ${count}`
            }
          />
        </CardContent>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => ({
  admin: state.admin,
});

export default connect(mapStateToProps, { getCustomers })(CustomerPage);
