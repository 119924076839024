import React, { useState, useEffect } from 'react';
import axios from 'axios';

//MUI 
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const ConfirmAction = ({ config }) => {
  const { open, handleClose, question, updateList, url, method } = config;
  const [error, setError] = useState('');
  useEffect(() => {
    setError('')
  }, [])
  const makeAction = () => {
    if (url) {
      axios({
        method, url
      })
        .then((res) => {
          updateList();
          setError('');
          handleClose();
        })
        .catch((err) => {
          setError('Произошла ошибка, повторите ещё раз')
        })
    } else {
      updateList();
    }
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle id="alert-dialog-title">ВНИМАНИЕ</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {question}
        </DialogContentText>
        <p>{error}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={makeAction} color="primary">
          Да
          </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Нет
          </Button>
      </DialogActions>
    </Dialog>
  );
}
export default ConfirmAction;