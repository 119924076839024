/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTime } from 'hooks/timeHook';

const RadioDates = ({ dateState, setDateState }) => {
  const [dateType, setDateType] = useState('today');
  const { timeAgoDates, firstMonthDay, formatDate } = useTime();

  useEffect(() => {
    if (dateType === 'today') {
      setDateState({
        date_start: new Date(),
        date_end: new Date()
      })
    } else if (dateType === 'yesterday') {
      setDateState(timeAgoDates(
        {
          date_start: new Date(),
          date_end: new Date(),
        }, 'day', 1))
    }
    else if (dateType === 'weekAgo') {
      setDateState(timeAgoDates(
        {
          date_start: new Date(),
          date_end: new Date(),
        }, 'day', 7))
    }
    else if (dateType === 'twoWeeksAgo') {
      setDateState(timeAgoDates(
        {
          date_start: new Date(),
          date_end: new Date(),
        }, 'day', 14))
    } else if (dateType === 'fromMonthStart') {
      setDateState(timeAgoDates({
        date_start: formatDate(firstMonthDay(), 'YYYY-MM-DD'),
        date_end: formatDate(new Date(), 'YYYY-MM-DD')
      }))
    }
  }, [dateType])

  useEffect(() => {
    let actualDate = timeAgoDates(dateState);
    if (actualDate.date_start === formatDate(firstMonthDay(), 'YYYY-MM-DD') && actualDate.date_end === formatDate(new Date(), 'YYYY-MM-DD')) {
      setDateType('fromMonthStart');
      return;
    }
    if (actualDate.date_end !== actualDate.date_start) {
      setDateType('')
    }
  }, [dateState])

  const changeDateType = (e) => {
    setDateType(e.target.value);
  }

  return (
    <FormControl component="fieldset">
      <RadioGroup row value={dateType} onChange={changeDateType}>
        <FormControlLabel value="today" control={<Radio />} label="Сегодня" />
        <FormControlLabel value="yesterday" control={<Radio />} label="Вчера" />
        <FormControlLabel value="weekAgo" control={<Radio />} label="Неделю назад" />
        <FormControlLabel value="twoWeeksAgo" control={<Radio />} label="Две недели назад" />
        <FormControlLabel value="fromMonthStart" control={<Radio />} label="С начала месяца" />
      </RadioGroup>
    </FormControl>
  )
}

export default RadioDates
