import React from "react";
import { Link } from "react-router-dom";

//MUI
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
const styles = (theme) => ({
  stageButton: {
    width: "45%",
    margin: "15px 0",
  },
  buttonWrap: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
});
const Kitchen = (props) => {
  const { classes } = props;
  return (
    <Dialog open={true}>
      <DialogTitle id="alert-dialog-title">
        Выберите стадию для планшета
      </DialogTitle>
      <DialogContent>
        <Grid className={classes.buttonWrap}>
          <Button
            to={`/kitchen/one`}
            component={Link}
            size="large"
            className={classes.stageButton}
            color="primary"
            variant="contained"
          >
            Раскатка теста
          </Button>
          <Button
            to={`/kitchen/two`}
            component={Link}
            size="large"
            className={classes.stageButton}
            color="primary"
            variant="contained"
          >
            Начинка
          </Button>
          <Button
            to={`/kitchen/three`}
            component={Link}
            size="large"
            className={classes.stageButton}
            color="primary"
            variant="contained"
          >
            Печь
          </Button>
          <Button
            to={`/kitchen/four`}
            component={Link}
            size="large"
            className={classes.stageButton}
            color="primary"
            variant="contained"
          >
            Финальная стадия
          </Button>
          <Button
            to={`/kitchen/five`}
            component={Link}
            size="large"
            className={classes.stageButton}
            color="primary"
            variant="contained"
          >
            Суши/Ролы
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(Kitchen);
