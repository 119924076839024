import React, { useState } from 'react'
import HintIcon from '../../../components/HintIcon';
//Redux
import { connect } from 'react-redux';
import { deleteProduct, changeQuantity, editPizza, editCombo, changePriceThroughService } from '../../../redux/actions/cartActions';
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { pink } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
//Icons
import Delete from '@material-ui/icons/Delete';
import Message from '@material-ui/icons/Message';
import Edit from '@material-ui/icons/Edit';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

const styles = (theme) => ({
  iconBackground: {
    background: '#4527a0'
  },
  combo: {
    background: '#e7c4ff'
  },
  wrap: {
    position: 'relative',
    padding: '5px',
    marginBottom: '10px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  disabledItem: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: '10',
    background: 'rgba(0, 0, 0, 0.1)',
  },
  title: {
    fontWeight: 'normal',
    display: 'block',
    width: '100%',
    paddingBottom: '5px '
  },
  size: {
    fontSize: '1rem',
    display: 'block',
    paddingBottom: '5px'
  },
  trashButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    border: 'none',
    background: 'none',
  },
  editButton: {
    cursor: 'pointer',
    position: 'absolute',
    top: '-5px',
    right: '35px',
    border: 'none',
    background: 'none',
  },
  commentButton: {
    position: 'absolute',
    top: '-5px',
    right: '80px'
  },
  commentTip: {
    background: pink[700],
    fontSize: '24px'
  },
  description: {
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between'
  },
  deleteButton: {
    color: pink[700]
  }
})

const CartItem = (props) => {
  const { classes, item, item: {
    priceBeforeCoupon, description, combo, type, quantity, comment, options, render_id, name, price
  }, deleteProduct, changeQuantity, editPizza, editCombo, setCommentItem, cart, changePriceThroughService } = props;
  const { pizza, sauce } = options;
  const [openEdit, setOpenEdit] = useState(false);
  const [priceValue, setPriceValue] = useState(price);
  let pizzaContent = null;
  let comboContent = null;
  let editButton =
    <IconButton onClick={() => combo ? editCombo(item) : editPizza(item)} className={classes.editButton} >
      <Edit style={{ color: pink[700] }} />
    </IconButton>

  if (combo) {
    comboContent = (
      <>
        {editButton}
        <Grid>
          <p className={classes.size}>{description}</p>
        </Grid>
      </>
    )
  }

  const hintIconConfig = {
    alwaysShow: true,
    class: classes.commentButton,
    placement: "bottom",
    text: comment,
    opened: true, actionFunc: () => setCommentItem(item)
  }

  let commentButton = comment ? (
    <HintIcon config={hintIconConfig}>
      <Message style={{ color: pink[700] }} />
    </HintIcon>
  ) : (
    <IconButton onClick={() => setCommentItem(item)} className={classes.commentButton}>
      <Message style={{ color: pink[700] }} />
    </IconButton>
  );

  if (pizza) {
    let pizzaDescription = `${pizza.value}см, ${pizza.name} `;
    let sauceDescription = sauce ? (`Соус ${sauce.value} 1шт - ${sauce.price}руб.`) : (`Без соуса`);

    pizzaContent =
      <>
        <Grid>
          <p className={classes.size}>{pizzaDescription}</p>
          <p className={classes.size}>{sauceDescription}</p>
        </Grid>
        {editButton}
      </>
  }
  return (
    <Card className={`${classes.wrap} ${combo ? classes.combo : ''}`}>
      <Grid className={type ? classes.disabledItem : null} />
      <Typography variant="h6" className={classes.title} color="primary">{name}</Typography>
      {pizzaContent}
      {comboContent}
      {commentButton}
      <Grid className={classes.description}>
        <div>
          <IconButton onClick={() => changeQuantity(price, render_id, 'reduce')} color="primary" size="medium">
            <ChevronLeft />
          </IconButton>
          <span className={classes.count}>{quantity}шт.</span>
          <IconButton onClick={() => changeQuantity(price, render_id, 'increase')} color="primary" size="medium">
            <ChevronRight />
          </IconButton>
        </div>
        {
          priceBeforeCoupon ? <span style={{ textDecoration: 'line-through' }} className={classes.price}>{priceBeforeCoupon}р.</span> : null
        }
        {
          openEdit ? (
            <Grid style={{ display: 'flex' }}>
              <TextField value={priceValue} onChange={(e) => setPriceValue(e.target.value)} />
              <Button onClick={() => { 
                setOpenEdit(false);
                changePriceThroughService(render_id, priceValue)
              }} color="primary">Сохранить</Button>
            </Grid>
          ) : (
              <>
                <span className={classes.price}>{price}р.</span>
                {
                  cart.order.platform_id === 3 || cart.order.platform_id === 4 ? (
                    <IconButton onClick={() => setOpenEdit(true)} className={classes.editPrice} >
                      <Edit className={classes.deleteButton} />
                    </IconButton>
                  ) : null
                }
              </>
            )
        }

        <span>{price * quantity}р.</span>
      </Grid>
      <IconButton onClick={() => deleteProduct(render_id)} className={classes.trashButton} >
        <Delete className={classes.deleteButton} />
      </IconButton>

    </Card >
  )
}

const mapStateToProps = (state) => ({
  cart: state.cart
})
export default connect(mapStateToProps, { changePriceThroughService, editCombo, deleteProduct, changeQuantity, editPizza })(withStyles(styles)(CartItem));