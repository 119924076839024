import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/ru';
import utc from 'dayjs/plugin/utc';
import calendar from 'dayjs/plugin/calendar';
import weekday from 'dayjs/plugin/weekday'

export const useTime = () => {
  dayjs.extend(relativeTime);
  dayjs.extend(calendar);
  dayjs.extend(weekday)
  dayjs.extend(utc);
  dayjs.locale('ru');

  const formatDate = (date, format) => {
    return dayjs(date).format(format)
  }

  const dateFromNow = (date) => {
    return dayjs(date).fromNow();
  }

  const firstMonthDay = (date) => {
    return dayjs().utc().startOf('M');
  }

  const deliveryTime = (date) => {
    return dayjs(date).calendar(null, {
      sameDay: '[сегодня в] HH:mm',
      nextDay: '[завтра]',
      nextWeek: 'dddd',
      lastDay: '[вчера в] HH:mm',
      lastWeek: '[Прошлый] dddd',
      sameElse: 'DD/MM/YYYY'
    });
  }

  const timeAgoDates = (date, type, timeAgo) => {
    let newDate = {}
    if (!type && !timeAgo) {
      newDate = {
        date_start: dayjs(date.date_start).format('YYYY-MM-DD'),
        date_end: dayjs(date.date_end).format('YYYY-MM-DD')
      }
    } else {
      newDate = {
        date_start: dayjs(date.date_start).subtract(timeAgo, type).format('YYYY-MM-DD'),
        date_end: dayjs(date.date_end).subtract(timeAgo, type).format('YYYY-MM-DD')
      }
    }
    if (newDate.date_start === newDate.date_end) {
      newDate = {
        ...newDate,
        weekDay: dayjs(newDate.date_start).format('dddd')
      }
    }
    return newDate
  }


  return {
    formatDate,
    dateFromNow,
    deliveryTime,
    firstMonthDay,
    timeAgoDates,
  }
}