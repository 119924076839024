import React, { useState, useRef } from 'react'
//MUI
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  inputWrap: {
    position: 'relative'
  },
  iconShow: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    zIndex: 100
  },
})
const EyeInputField = ({ classes, configs }) => {
  const [show, setShow] = useState(false);
  const inputEl = useRef(null);
  const showPassword = (e) => { 
    document.querySelector(`#${configs.name}`).type = 'text';
    setShow(true)
  }
  const hidePassword = (e) => {
    document.querySelector(`#${configs.name}`).type = 'password';
    setShow(false)
  }
  const eyeIcon = !show ? (<IconButton className={classes.iconShow} onClick={(e) => showPassword(e)}>
    <VisibilityIcon />
  </IconButton>)
    :
    (<IconButton className={classes.iconShow} onClick={(e) => hidePassword(e)}>
      <VisibilityOffIcon />
    </IconButton>)
  return (
    <Grid className={classes.inputWrap}>
      <TextField
        ref={inputEl}
        id={configs.name}
        type="password" 
        fullWidth
        {...configs}
      />
      {eyeIcon}
    </Grid>
  )
}

export default withStyles(styles)(EyeInputField)
