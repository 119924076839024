import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({

})

const OptionsPackageWindow = ({ setAddedPackageList, item, handleClose, open }) => {

  const addPackagePizza = (option) => {
    let correctOption = {
      package_option_id: option.id,
      name: option.name,
      value: option.value
    }

    let targetProduct = {
      name: item.name,
      package_id: item.id,
      quantity: 1,
      options: [correctOption]
    }

    setAddedPackageList(prev => {
      return [
        ...prev,
        targetProduct
      ]
    })
    handleClose();
  }

  return (
    <Dialog open={open}>
      <DialogContent>
        <Grid style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
          {
            item && item.options ? item.options.filter((option) => option.name !== 'Соусы').map((option) => {
              return <Button key={option.id} style={{ marginBottom: '1rem' }} variant="outlined" color="primary" onClick={() => addPackagePizza(option)}>{`${option.name} ${option.value}`}</Button>
            }) : null
          }
        </Grid>

      </DialogContent>
    </Dialog>
  )
}

export default withStyles(styles)(OptionsPackageWindow);