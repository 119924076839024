/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import ReusableTabs from 'components/ReusedTabs';
import IngredientsTable from './IngredientsTable';
import ConstituentsTable from './ConstituentsTable';
import PackagesTable from './PackagesTable';
import ComboTable from './ComboTable';
import { useHttp } from 'hooks/httpHook';
//MUI
import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles';
import PrimecostTable from './PrimecostTable';

const styles = theme => ({
  ...theme.tables,
  cardWrapper: {
    marginBottom: theme.spacing(2),
    width: '49.5%',
  },
})

const IngredientsCard = ({ classes, updated }) => {
  const [ingredients, setIngredients] = useState([]);
  const [constituents, setConstituents] = useState([]);
  const [packages, setPackages] = useState([]);
  const [products, setProducts] = useState([]);
  const [combos, setCombos] = useState([]);
  const { request, loading } = useHttp();

  const getIngredientsData = async () => {
    const data = await request('/admin/ingredients', 'get');
    if (data) {
      setIngredients(data);
    }
  }

  const getPackagesData = async () => {
    const data = await request('/admin/packages', 'get');
    if (data) {
      setPackages(data);
    }
  }

  const getConstituentsData = async () => {
    const data = await request('/admin/constituents', 'get');
    if (data) {
      setConstituents(data)
    }
  }

  const getProductsData = async () => {
    const data = await request('/admin/products/primecost', 'get');
    if (data) {
      setProducts(data);
    }
  }

  const getComboData = async () => {
    const data = await request('/admin/products/combos', 'get');
    if (data) {
      setCombos(data)
    }
  }

  useEffect(() => {
    getIngredientsData();
    getConstituentsData();
    getPackagesData();
    getProductsData();
    getComboData();
  }, [])

  const tabsInfo = [
    {
      component: <PrimecostTable loading={loading} products={products} getData={getProductsData} />,
      title: 'Продукты'
    },
    {
      component: <ComboTable loading={loading} combos={combos} getData={getComboData} />,
      title: 'Комбо'
    },
    {
      component: <IngredientsTable loading={loading} ingredients={ingredients} getData={getIngredientsData} />,
      title: "Ингредиенты"
    },
    {
      component: <ConstituentsTable loading={loading} constituents={constituents} getData={getConstituentsData} />,
      title: "Составляющие"
    },
    {
      component: <PackagesTable loading={loading} packages={packages} getData={getPackagesData} />,
      title: 'Упаковка'
    }
  ]

  return (
    <Card className={classes.cardWrapper}>
      <ReusableTabs tabsContent={tabsInfo} />
    </Card>
  )
}

export default withStyles(styles)(IngredientsCard);