import React, { useState, useEffect } from 'react'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { useHttp } from 'hooks/httpHook';
import withStyles from '@material-ui/core/styles/withStyles';
import MenuItem from '@material-ui/core/MenuItem';
const styles = theme => ({
  formControl: {
    minWidth: '200px'
  },
})
const CouponSelect = ({ handleChange, classes }) => {
  const [coupons, setCoupons] = useState([]);
  const [value, setValue] = useState('');
  const { request } = useHttp();

  const getData = async () => {
    let result = await request('/admin/coupons', 'get');
    if (result) {
      setCoupons(result.reverse());
    }
  }

  useEffect(() => {
    getData()
  }, [])

  const changeCoupon = (e) => {
    if (!e.target.value) {
      setValue("");
      handleChange({});
      return;
    }
    let chosenCoupon = coupons.find(item => item.code === e.target.value);
    handleChange(chosenCoupon);
    setValue(e.target.value);
  }

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-simple-select-label">Купон</InputLabel>
      <Select
        value={value}
        onChange={changeCoupon}
      >
        {coupons ? coupons.map((coupon) => {
          return <MenuItem key={coupon.id} value={coupon.code}>{coupon.code} ({coupon.name})</MenuItem>
        }) : null}
        <MenuItem value={""}>Без купона</MenuItem>
      </Select>
    </FormControl>
  )
}

export default withStyles(styles)(CouponSelect)
