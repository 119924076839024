/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import CartItem from './CartItem';
import { connect } from 'react-redux';
import { closeMakeCombo, submitPayment, changeComment } from '../../redux/actions/cartActions';
import CartAdd from './PizzaDetails';
import CartComment from './CartComment';
import PhoneInfo from './PhoneInfo';
import MakeCombo from './MakeCombo';
import axios from 'axios';
//MUI 
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const styles = (theme) => ({
  ...theme.cart
})

const Cart = ({ changeComment, classes, cart, data: { orderId }, cart: { promoAdded, order, products }, closeMakeCombo }) => {
  const [openPhone, setOpenPhone] = useState(false);
  const [commentItem, setCommentItem] = useState({});
  const cartElems = cart.products.map(item => <CartItem setCommentItem={setCommentItem} key={item.render_id} item={item} />);
  const cartContent = cart.cartAddOpened ? (<CartAdd />) : (cartElems);
  const totalPrice = cart.totalPrice === undefined ? 0 : cart.totalPrice;
  const goToPay = () => {
    setOpenPhone(true);
  }

  const backToCash = async () => {
    await axios.delete(`/admin/orders/${orderId}`)
      .catch(err => console.log(err.response.data))

    window.location.href = '/cash'
  }
  return (
    <>
      <MakeCombo open={cart.makeCombo} closeMakeCombo={closeMakeCombo} editCombo={cart.editCombo} />
      <PhoneInfo openPhone={openPhone} setOpenPhone={setOpenPhone} />
      <CartComment defaultValue={commentItem.comment} product={commentItem} addComment={changeComment} openComment={Boolean(commentItem.name)} setOpenComment={setCommentItem} />
      <div className={classes.cartWrap}>
        <div className={classes.cartContent}>
          <Grid className={classes.cartTitleWrap}>
            <Typography className={classes.cartTitle} align="center" variant="h1">Корзина</Typography>
            <Tooltip placement="bottom" title="Обратно в список заказов">
              <IconButton onClick={backToCash} className={classes.backToCash}>
                <ArrowBackIcon fontSize="large" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid className={classes.cartList}>
            {cartContent.length === 0 ? 'Корзина пуста' : cartContent}
          </Grid>
          <Grid className={classes.finalStage}>
            <Grid className={classes.totalInfo}>
              <Button onClick={() => goToPay()} disabled={cart.products.length < 1} variant="contained" color="primary">К оплате</Button>
              <Typography className={classes.totalPrice} variant='h5'>{totalPrice} руб.</Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </>

  )
}
const mapStateToProps = state => ({
  cart: state.cart,
  data: state.data,
})
export default connect(mapStateToProps, { submitPayment, closeMakeCombo, changeComment })(withStyles(styles)(Cart));
