import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
import HintIcon from 'components/HintIcon';
import { pink } from '@material-ui/core/colors';
import ShippingIcon from '@material-ui/icons/LocalShipping';
import Grid from '@material-ui/core/Grid';

const styles = theme => ({
  hintBold: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
})

const DeliveryDetailsIcon = ({ order, classes }) => {
  const { shipping_address, shipping_method, porch, floor, porch_code, flat } = order;
  const deliveryHint =
    <Grid>
      <p><span className={classes.hintBold}>Способ доставки:</span> {shipping_method === 'delivery' ? 'доставка' : 'Самовывоз'}</p>
      <p><span className={classes.hintBold}>Адрес доставки: {shipping_method === 'delivery' ? `${shipping_address}, подъезд ${!porch ? 'не указан' : porch}, этаж ${!floor ? 'не указан' : floor}, домофон ${!porch_code ? 'не указан' : porch_code}, квартира ${!flat ? 'не указана' : flat}` : 'отсутствует'}</span></p>
    </Grid>
  return (
    <HintIcon config={{ placement: "top", text: deliveryHint, opened: false }}>
      <ShippingIcon style={{ color: pink[700] }} />
    </HintIcon>
  )
}

export default withStyles(styles)(DeliveryDetailsIcon)
