/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import SearchInput from 'components/SearchInput';
import ConfirmAction from 'components/ConfirmAction';
import TableSkeleton from 'util/skeletons/UserTableSkeleton'
//MUI
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Toolbar from '@material-ui/core/Toolbar';
import TableRow from '@material-ui/core/TableRow';
import TablePagination from '@material-ui/core/TablePagination';
//icons
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import PackagesWindow from './PackagesWindow';

const styles = theme => ({
  ...theme.tables,
  tableTools: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cellName: {
    transition: '0.3s',
    cursor: 'pointer',
    '& > *': {
      borderBottom: 'unset',
    },
  },
  ingredientDescription: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    paddingBottom: '10px'
  },
  ingredientItem: {
    width: '30%',
    marginRight: '3%',
    padding: '5px',
    marginBottom: theme.spacing(1),
    borderRadius: '5px',
    border: `1px solid rgba(0, 0, 0, 0.2)`,
  }
})

const PackagesTable = ({ classes, getData, packages, loading }) => {
  const [value, setValue] = useState('');
  const [open, setOpen] = useState(false);
  const [packageItem, setPackageItem] = useState({});
  const [active, setActive] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openDelete, setOpenDelete] = useState(false);
  const collapseRef = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  }

  const handleOpenDelete = (item) => {
    setPackageItem(item);
    setOpenDelete(true);
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteMethods = {
    method: 'DELETE',
    open: openDelete,
    handleClose: handleCloseDelete,
    question: `Вы действительно хотите удалить упаковку ${packageItem.name}?`,
    updateList: getData,
    url: `/admin/packages/${packageItem.id}`
  }

  return (
    <>
      <ConfirmAction config={deleteMethods} />
      <PackagesWindow getData={getData} handleClose={handleClose} open={open} packageItem={packageItem} />
      <CardContent style={{ paddingTop: '2rem' }}>
        <Toolbar className={classes.tableTools}>
          <SearchInput setValue={setValue} />
          <Button onClick={() => {
            handleClickOpen();
            setPackageItem({});
          }} color="primary" variant="contained">
            <AddIcon />
          </Button>
        </Toolbar>
        <Table size="small" className={classes.table} >
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Название</TableCell>
              <TableCell className={classes.tableHeader} align="right" />
            </TableRow>
          </TableHead>
          <ClickAwayListener onClickAway={() => setActive(null)}>
            <TableBody>
              {!loading ? packages.length ? packages
                .filter((item) => {
                  let name = item.name.toLowerCase();
                  let inputValue = value.toLowerCase();
                  if (name.indexOf(inputValue) + 1) {
                    return item;
                  } else {
                    return null;
                  }
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item) => (
                  <React.Fragment key={item.id}>
                    <TableRow ref={collapseRef} className={classes.cellName} key={item.name} onClick={(e) => {
                      if (active === item.id) {
                        setActive(null)
                      } else {
                        setActive(item.id)
                      }
                    }}>
                      <TableCell component="th" scope="row">
                        {item.name}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton size="small" onClick={() => {
                          setPackageItem(item);
                          handleClickOpen();
                        }}>
                          <EditIcon color="secondary" />
                        </IconButton >
                        <IconButton size="small" onClick={() => handleOpenDelete(item)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                        <Collapse in={active === item.id} timeout="auto" unmountOnExit>
                          <Grid className={classes.ingredientDescription}>
                            {
                              item.options.length ? item.options.map((option, index) => {
                                return (
                                  <Grid key={index} className={classes.ingredientItem}>
                                    <p>{option.dough_size} - {option.dough_type}</p>
                                    <p>Цена: <span style={{ fontWeight: 'bold' }}>{option.price}руб.</span></p>
                                  </Grid>
                                )
                              }) : item.products.objects.map((product, index) => {
                                return (
                                  <Grid key={index} className={classes.ingredientItem}>
                                    <p>{product.name}</p>
                                    <p>Цена: <span style={{ fontWeight: 'bold' }}>{product.price}руб.</span></p>
                                  </Grid>
                                )
                              })
                            }
                          </Grid>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                )) : null : <TableSkeleton />}
            </TableBody>
          </ClickAwayListener>
        </Table>
        <TablePagination
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} из ${count}`}
          labelRowsPerPage="Элементов на странице"
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={packages.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </CardContent>
    </>
  )
}

export default withStyles(styles)(PackagesTable);
