import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useHttp } from 'hooks/httpHook';
import { setClientPhone, submitPayment } from '../../../redux/actions/cartActions';
import InputMask from 'react-input-mask'
//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = theme => ({
  promoButtons: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  promoInput: {
    marginBottom: '15px'
  },
  promoWrap: {
    padding: 24,
  },
  promoButton: {
    marginBottom: '10px'
  }
})

const PhoneInfo = ({ openPhone, setOpenPhone, classes, setClientPhone, data: { deliveryOrder }, cart: { phone, submitCount }, submitPayment }) => {
  const { request, loading } = useHttp();
  const [errors, setErrors] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [number, setNumber] = useState(phone);
  const history = useHistory();

  const handleChange = e => {
    setErrorMessage('');
    setNumber(e.target.value.replace(/[^+0-9]/gim, ''));
  }

  useEffect(() => {
    if (number.length < 12) {
      setErrors(true);
    } else {
      setErrors(false);
    }
  }, [number])

  const checkBlackList = async () => {
    const data = await request('/blacklist/find', 'post', { telephone: number });
    if (data && !data.success) {
      setErrorMessage('Данный номер телефона в черном списке');
    } else {
      await setClientPhone(number);
      setErrors(false);
      await setOpenPhone(false);
      if (submitCount === 0) {
        await submitPayment();
      }
      if (deliveryOrder) {
        history.push('/cash/customer-info')
      } else {
        history.push('/cash/pay')
      }
    }
  }

  const sendPhoneToState = async () => {
    checkBlackList();
  }


  const skipPhone = () => {
    setOpenPhone(false);
    if (submitCount === 0) {
      submitPayment();
    }
    if (deliveryOrder) {
      history.push('/cash/customer-info')
    } else {
      history.push('/cash/pay')
    }
  }

  return (
    <Dialog onClose={() => setOpenPhone(false)} className={classes.promoWrap} open={openPhone} fullWidth maxWidth="sm">
      <DialogTitle>{"Номер телефона клиента"}</DialogTitle>
      <FormControl error={!!errorMessage}>
        <DialogContent>
          <InputMask mask="+7 (999) 999-99-99" defaultValue={phone} onChange={(e) => handleChange(e)}>
            <TextField autoFocus fullWidth className={classes.promoInput} label="Введите номер телефона" variant="outlined" />
          </InputMask>
          <Grid className={classes.promoButtons}>
            <Button className={classes.promoButton} onClick={() => setOpenPhone(false)} color="primary">Вернуться</Button>
            <Grid>
              <Button className={classes.promoButton} onClick={() => skipPhone()} color="primary">Пропустить</Button>
              <Button disabled={errors || !!errorMessage} className={classes.promoButton} onClick={() => sendPhoneToState()} variant="contained" color="primary">Перейти к оплате</Button>
            </Grid>
          </Grid>
          <FormHelperText>{errorMessage}</FormHelperText>
        </DialogContent>
      </FormControl>
    </Dialog>
  )
}

const mapStateToProps = state => ({
  cart: state.cart,
  data: state.data,
})
export default connect(mapStateToProps, { submitPayment, setClientPhone })(withStyles(styles)(PhoneInfo));
