/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHttp } from 'hooks/httpHook';
import ComboDiagram from "./ComboDiagram";
import IngredientPie from './IngredientDiagram';
import ProductsDiagram from './ProductsDiagram';
import MainIngredientDiagramm from './MainIngredientDiagram';
import currency from 'util/currency';
import DateSchedule from 'components/DateSchedule';
import { useTime } from 'hooks/timeHook';
import ReusableTabs from 'components/ReusedTabs';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import CouponSelect from 'components/CouponSelect';
//MUI
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';
const styles = theme => ({
  ...theme.mainStyles,
  analyticsBlock: {
    position: 'relative'
  },
  pie: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  loadingLine: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: 0,
    width: '50%'
  }
})

const IngredientsTab = ({ classes }) => {
  const [data, setData] = useState({});
  const [dataWriteoff, setDataWriteoff] = useState({});
  const [writeoffs, setWriteoffs] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [products, setProducts] = useState([])
  const [combos, setCombos] = useState([]);
  const [doughData, setDoughData] = useState([]);
  const [constData, setConstData] = useState([]);
  const [coupon, setCoupon] = useState({});
  const { firstMonthDay, formatDate } = useTime();
  const [isToday, setIsToday] = useState({
    status: false,
    initial_date: null
  })
  const [dateState, setDateState] = useState({
    date_start: firstMonthDay().$d,
    date_end: new Date()
  });
  const { request, loading } = useHttp();

  const getData = async () => {
    const requestBody = dateState;
    requestBody.coupon = coupon.code
    const serverData = await request('/admin/analytics/products', 'post', requestBody);
    const separateData = await request('/admin/analytics/products/info', 'post', requestBody);
    const comboData = await request('/admin/analytics/products/combos', 'post', requestBody);
    const writeoffData = await request('/admin/analytics/writeoff', 'post', requestBody);

    if (writeoffData) {
      setDataWriteoff(writeoffData);
    }
    if (serverData) {
      setData(serverData);
    }
    if (separateData) {
      let data = Object.values(separateData);
      setProducts(data);
    }
    if (comboData) {
      setCombos(comboData);
    }
  }

  useEffect(() => {
    if (dateState.date_end && dateState.date_start) {
      getData();
    }
  }, [dateState, coupon])


  useEffect(() => {
    if (data.revenue) {
      let items = Object.values(data.ingredients);
      let packages = data.packages;
      let componentPackages = packages.total.filter((item) => item.name !== 'Коробка');
      let options = Object.values(packages.options);
      options.forEach((array, index) => {
        array.forEach((item) => {
          if (item.name === 'Коробка') {
            item.name = item.name + ' - ' + Object.keys(packages.options)[index];
            componentPackages.push(item);
          }
        })
      });
      items.push(...componentPackages);
      let constituents = false;
      if (data.constituents.data) {
        constituents = Object.values(data.constituents.data);
      }
      let doughIngredients = items.filter((item) => item.name === 'Тесто' || item.name === 'Сыр моцарелла');
      items = items.filter(function (item) {
        return !doughIngredients.some(function (item2) {
          return item.name === item2.name;
        })
      });
      setIngredients(items);
      if (constituents) {
        setConstData(constituents);
      }
      setDoughData(doughIngredients);
    }
  }, [data])

  useEffect(() => {
    if (dataWriteoff.purchase) {
      let items = Object.values(dataWriteoff.ingredients);
      let packages = dataWriteoff.packages;
      let componentPackages = packages.total.filter((item) => item.name !== 'Коробка');
      let options = Object.values(packages.options);
      options.forEach((array, index) => {
        array.forEach((item) => {
          if (item.name === 'Коробка') {
            item.name = item.name + ' - ' + Object.keys(packages.options)[index];
            componentPackages.push(item);
          }
        })
      });
      items.push(...componentPackages);
      setWriteoffs(items);
    } else {
      setWriteoffs([]);
    }
  }, [dataWriteoff])

  const dateConfig = {
    flexWrap: true,
    dateState,
    setDateState,
  }

  const tabsInfo = [
    {
      component: <ProductsDiagram data={products} />,
      title: "Позиции"
    },
    {
      component: <ComboDiagram data={combos} />,
      title: "Комбо"
    },
    {
      component: <MainIngredientDiagramm data={ingredients} />,
      title: "Ингредиенты"
    },
    {
      component: <IngredientPie data={doughData} hideFilter={true} />,
      title: "Тесто"
    },
    {
      component: <IngredientPie data={constData} hideFilter={true} />,
      title: "Составляющие"
    },
    {
      component: <MainIngredientDiagramm data={writeoffs} />,
      title: "Списания"
    },

  ]

  const changeCheckboxDate = (e) => {
    setIsToday({
      status: e.target.checked,
      initial_date: e.target.checked ? dateState : isToday.initial_date
    })
  }

  useEffect(() => {
    if (isToday.initial_date) {
      if (isToday.status) {
        setDateState({
          date_start: formatDate(new Date(), "YYYY-MM-DD"),
          date_end: formatDate(new Date(), "YYYY-MM-DD")
        })
      } else {
        setDateState(isToday.initial_date)
      }
    }
  }, [isToday.status]);

  return (
    <Card className={classes.analyticsBlock}>
      <CardContent >
        <Grid className={`${classes.flexbox} ${classes.flexboxAlign}`}>
          <Grid style={{ position: 'relative' }}>
            <CouponSelect handleChange={setCoupon} />
            <DateSchedule {...dateConfig} />
            <FormControlLabel
              control={<Checkbox checked={isToday.status} onChange={changeCheckboxDate} color="primary" />}
              label="За сегодняшний день"
              labelPlacement="end"
            />
            {
              loading ? <LinearProgress className={classes.loadingLine} /> : null
            }
          </Grid>
          <Grid>
            <Typography variant="body1">
              Выручка: {data.revenue ? currency(data.revenue) : 0}
            </Typography>
            <Typography variant="body1">
              Потрачено на ингредиенты: {data.total ? currency(data.total) : 0} ({data.total && (data.total / data.revenue * 100).toFixed(2)}%)
            </Typography>
            <Typography variant="body1">
              Себестоимость: {data.purchase ? currency(data.purchase) : 0} ({data.revenue && (data.purchase / (parseInt(data.revenue) / 100)).toFixed(2)}%)
            </Typography>
            <Typography variant="body1">
              Списание: {dataWriteoff.purchase ? currency(dataWriteoff.purchase) : 0} ({data.revenue && (dataWriteoff.purchase / (parseInt(data.revenue) / 100)).toFixed(2)}%)
            </Typography>
          </Grid>
        </Grid>
        <ReusableTabs tabsContent={tabsInfo} />
      </CardContent>
    </Card>
  )
}

export default withStyles(styles)(IngredientsTab)
