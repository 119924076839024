import React, {useEffect, useState} from "react";
import Dialog        from "@material-ui/core/Dialog";
import axios         from "axios";
import Grid          from "@material-ui/core/Grid";
import InputLabel    from "@material-ui/core/InputLabel";
import NativeSelect  from "@material-ui/core/NativeSelect";
import TextField     from "@material-ui/core/TextField";
import withStyles    from "@material-ui/core/styles/withStyles";
import Button        from "@material-ui/core/Button";
import {ButtonGroup} from "@material-ui/core";
import DeleteIcon    from '@material-ui/icons/Delete';
import IconButton    from '@material-ui/core/IconButton';
import Alert         from "@material-ui/lab/Alert";

const styles = theme => ({
  orderTitle   : {
    display       : 'flex',
    justifyContent: 'space-between',
    marginBottom  : theme.spacing(1)
  },
  orderContent : {
    width       : '100%',
    minHeight   : '200px',
    height      : '428px',
    background  : '#F3F3F3',
    borderRadius: '10px',
    padding     : theme.spacing(1),
    position    : 'relative'
  },
  orderWrap    : {
    padding  : theme.spacing(2),
    minHeight: '500px',
  },
  payContainer : {
    position     : 'absolute',
    bottom       : theme.spacing(1),
    right        : theme.spacing(1),
    display      : 'flex',
    flexDirection: 'column',
  },
  orderInfo    : {
    position: 'absolute',
    bottom  : theme.spacing(1),
    left    : theme.spacing(1),
    width   : '60%',

  },
  infoContainer: {
    width       : '100%',
    background  : '#E5DDF2',
    borderRadius: '10px',
    marginTop   : theme.spacing(1),
    padding     : theme.spacing(1),
    color       : '#6A6A6A'
  },
  confirmButton: {
    background  : '#E5DDF2',
    color       : '#6A6A6A'
  },
})

const ProductsWindow = ({classes, open, setOpen, setProducts, products}) => {

  const [mainProducts, setMainProducts] = useState([]);
  const [initProducts, setInitProducts] = useState([]);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    if (products) {
      getProducts();
      setInitProducts(products)
    }
  }, [products, open])

  const handleChange = (e, id) => {

    initProducts[id] = {
      ...initProducts[id],
      [e.target.name]: parseInt(e.target.value),
      name         : e.target[e.target.selectedIndex].text
    }

    setInitProducts([
      ...initProducts
    ])
  }

  const handleDeleteItem = id => {
    const newProducts = initProducts.filter((item, i) => {
        return id !== i;
      })

    setInitProducts([
      ...newProducts
    ])
  }

  const handleAddItem = order_id => {

    const item = {
      comment: '',
      name: 'Чизбургер',
      options: [{
        name: 'Традиционное',
        order_id,
        product_option_id: 76,
        value: '30'
      }],
      order_id,
      price: 0,
      product_id: 14
    }

    setInitProducts([
      ...initProducts,
      item
    ])
  }

  const handleAdd = order_id => {

    const item = {
      comment: '',
      name: 'Ролл с курочкой',
      options: [],
      order_id,
      price: 0,
      product_id: 35
    }

    setInitProducts([
      ...initProducts,
      item
    ])
  }

  const handleC = (e, id) => {

    initProducts[id] = {
      ...initProducts[id],
      [e.target.name]: e.target.value
    }

    setInitProducts([
      ...initProducts
    ])
  }

  const getProducts = async () => {
    await axios.get('products?categories[]=2&categories[]=3&categories[]=4&categories[]=5&categories[]=6&categories[]=7')
      .then(res => setMainProducts(res.data))
      .catch(e => console.log(e));
  }

  const handleChangeOption = (e, id, product_index) => {
    initProducts[product_index]['options'][id] = {
      ...initProducts[product_index]['options'][id],
      [e.target.name]: parseInt(e.target.value),
      name: e.target[e.target.selectedIndex].getAttribute('data-option_name'),
      value: e.target[e.target.selectedIndex].getAttribute('data-option_value')
    }

    setInitProducts([
      ...initProducts
    ])
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveProducts = () => {
    setProducts(initProducts)
    setInitProducts([])
    setOpen(false)
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Grid className={classes.orderWrap}>
      {initProducts && initProducts.map((product, i) => (
        <Grid key={i} container spacing={2}>
          <Grid item xs={4}>
            <InputLabel shrink htmlFor="is_site-native-label-placeholder">
              Товар
            </InputLabel>
            <NativeSelect
              value={product.product_id}
              style={{width: '100%'}}
              name="product_id"
              onChange={(e) => handleChange(e, i)}
            >
              {mainProducts.map((product, i) => {
                return <option key={i} value={product.id}>{product.name}</option>
              })}
            </NativeSelect>
          </Grid>
          <Grid item>
            <TextField
              id={product.name + product.id + i + Math.random()}
              label="Цена"
              value={product.price}
              name="price"
              onChange={(e) => handleC(e, i)}
            />
          </Grid>
          <Grid item>
            <TextField
              id={product.name + product.id + i + Math.random()}
              label="Комментарий"
              value={product.comment || ''}
              name="comment"
              onChange={(e) => handleC(e, i)}
            />
          </Grid>
          {product.options.map((option, index) => {
            return (
              <Grid item key={index}>
                <InputLabel shrink htmlFor="is_site-native-label-placeholder">
                  Опция
                </InputLabel>
                <NativeSelect
                  value={option.product_option_id}
                  style={{width: '100%'}}
                  name="product_option_id"
                  onChange={(e) => handleChangeOption(e, index, i)}
                >
                  {mainProducts.map((item, i) => {
                    if (product.product_id === item.id) {
                      return item.options.map((o, idx) => {
                        return <option key={o.id} value={o.id} data-option_name={o.name} data-option_value={o.value}>{o.name + " " + o.value}</option>
                      })
                    }
                  })}
                </NativeSelect>
              </Grid>
            )
          })}
          <Grid item>
            <IconButton onClick={() => handleDeleteItem(i)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{margin: '10px 0'}}>
          <Button onClick={() => handleAddItem(initProducts[0].order_id)} variant="contained" color="secondary">Добавить пиццу</Button>
          <Button onClick={() => handleAdd(initProducts[0].order_id)} variant="outlined" color="secondary">Добавить товар</Button>
        </ButtonGroup>

        <ButtonGroup color="primary" aria-label="outlined primary button group" style={{margin: '10px 0'}}>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button color="primary" variant="contained" onClick={handleSaveProducts}>Сохранить</Button>
        </ButtonGroup>
      </div>

      </Grid>
    </Dialog>
  )
}

export default withStyles(styles)(ProductsWindow)