import {
  LOADING_UI,
  CLEAR_ERRORS,
  SET_ERRORS
} from '../types';

const initialState = {
  loading: false,
  errors: null,
}

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      }
    case CLEAR_ERRORS:
      return {
        ...state,
        loading: false,
        errors: null,
      }
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: payload
      }
    default:
      return state
  }
}