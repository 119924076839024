import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

const AuthRedirect = () => {
  const { pathname } = useLocation();
  const role = localStorage.path;
  if (pathname === '/admin') {
    return <Redirect to={`/admin/database`} />
  }
  if (role === 'admin') {
    return null;
  }
  if (pathname.indexOf('login') > 0) {
    return <Redirect to={`/login`} />
  }
  
  if (pathname.indexOf(role) < 0 && pathname.indexOf('database') < 0) {
    return <Redirect to={`/${role}`} />
  }
  return null;
}

export default AuthRedirect
