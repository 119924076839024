/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react';
import { Context }    from 'hooks/contextHook';
import WriteOffSelect from '../WriteOffSelect';
import { useHttp }    from 'hooks/httpHook';
import OptionsWindow  from '../OptionsWindow'
import OptionsPackageWindow from "../OptionsPackageWindow";
import { useTime }    from 'hooks/timeHook';

//MUI
import withStyles from '@material-ui/core/styles/withStyles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog        from '@material-ui/core/Dialog';
import Grid          from '@material-ui/core/Grid';
import DialogContent from '@material-ui/core/DialogContent';
import Button        from '@material-ui/core/Button';
import Typography    from '@material-ui/core/Typography';
import DialogActions from '@material-ui/core/DialogActions';
import Alert         from '@material-ui/lab/Alert';
import Snackbar      from '@material-ui/core/Snackbar';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

//icons
import CloseIcon            from '@material-ui/icons/Close';
import IconButton           from '@material-ui/core/IconButton';
import FormControlLabel     from "@material-ui/core/FormControlLabel";
import Checkbox             from "@material-ui/core/Checkbox";
const styles = theme => ({
  formControl: {
    minWidth: 120,
    width: '48%'
  },
  selectWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    border: '1px solid grey',
    borderRadius: '5px'
  },
  select: {
    marginBottom: theme.spacing(1),
  },
  addedList: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexWrap: 'wrap',
  },
  listItem: {
    marginBottom: theme.spacing(1),
    padding: '5px 5px 5px 10px',
    background: theme.palette.primary.main,
    borderRadius: '50px',
    color: 'white',
    display: 'flex',
    alignItems: 'center'
  },
  closeButton: {
    marginLeft: '5px',
    background: 'white',
    '&:hover': {
      background: theme.palette.secondary.main
    }
  }
})

const WriteOffWindow = ({ classes, handleClose, open, getData }) => {
  const { products } = useContext(Context);
  const { formatDate } = useTime();
  const [positions, setPositions] = useState([]);
  const [addedList, setAddedList] = useState([]);
  const [addedIngredientList, setAddedIngredientList] = useState([]);
  const [addedPackageList, setAddedPackageList] = useState([]);
  const [pizzaItem, setPizzaItem] = useState({});
  const [packageItem, setPackageItem] = useState({});
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()), "YYYY-MM-DD");
  const [openOptions, setOpenOptions] = useState(false);
  const [openPackageOptions, setOpenPackageOptions] = useState(false);
  const { request, error, success } = useHttp();

  useEffect(() => {
    if (products.length) {
      let sortedProducts = sortArray(products);
      setPositions(sortedProducts);
    }
  }, [products]);

  const handleOpenOptions = (item) => {
    setOpenOptions(true);
    setPizzaItem(item);
  }

  const handleOpenPackageOptions = (item) => {
    setOpenPackageOptions(true);
    setPackageItem(item);
  }

  const sortArray = (items) => {
    let initialArray = items;
    let newArray = []
    for (let i = 0; i <= initialArray.length; i++) {
      let categoryName = initialArray[0].category;
      let firstArray = initialArray.filter(a => a.category === categoryName);
      if (firstArray.length) {
        newArray.push(firstArray);
        initialArray = initialArray.filter(a => a.category !== categoryName);
      }
    }
    return newArray;
  }

  const deleteItemList = (item) => {
    let newList = addedList.filter(a => item.name !== a.name);
    setAddedList(newList)
  }

  const deleteItemIngredientList = (item) => {
    let newList = addedIngredientList.filter(a => item.name !== a.name);
    setAddedIngredientList(newList)
  }

  const deleteItemPackageList = (item) => {
    let newList = addedPackageList.filter(a => item.name !== a.name);
    setAddedPackageList(newList)
  }

  const sendItems = async () => {
    let products = addedList.map(i => {
      i.created_at = formatDate(selectedDate, "YYYY-MM-DD");

      return i;
    })

    let ingredients = addedIngredientList.map(i => {
      i.created_at = formatDate(selectedDate, "YYYY-MM-DD");

      return i;
    })

    let packages = addedPackageList.map(i => {
      i.created_at = formatDate(selectedDate, "YYYY-MM-DD");

      return i;
    })

    await request('/admin/analytics/products/writeoffproduct', 'post', { products })
      .then(res => request('/admin/analytics/products/writeoffingredient', 'post', { ingredients }))
      .then(res => request('/admin/analytics/products/writeoffpackage', 'post', { packages }))
      .then(res => {
        setAddedList([]);
        setAddedIngredientList([]);
        setAddedPackageList([]);
        handleClose();
        getData();
      })
  }

  const handleChangeQuantity = (value, item) => {
    item.quantity = parseInt(value) || 0;
    let arr = addedList.filter(a => item.name !== a.name)

    arr = [...arr, item]
    setAddedList(arr)

  }

  const handleChangeWeight = (value, item) => {
    item.weight = parseInt(value) || 0;
    let arr = addedIngredientList.filter(a => item.name !== a.name)

    arr = [...arr, item]
    setAddedIngredientList(arr)
  }

  const handleChangeCount = (value, item) => {
    item.quantity = parseInt(value) || 0;
    let arr = addedPackageList.filter(a => item.name !== a.name)

    arr = [...arr, item]
    setAddedPackageList(arr)
  }

  const handleDateChange = value => {
    setSelectedDate(value)
  }

  const handleChange = item => {
    item.is_with_packaging = !item.is_with_packaging
    let arr = addedList.filter(a => item.name !== a.name)

    arr = [...arr, item]
    setAddedList(arr)
  }

  return (
    <Dialog fullWidth={true}
      maxWidth={'sm'}
      onClose={handleClose}
      open={open}>
      <OptionsWindow
        open={openOptions}
        item={pizzaItem}
        setAddedList={setAddedList}
        handleClose={() => setOpenOptions(false)} />
      <OptionsPackageWindow
        open={openPackageOptions}
        item={packageItem}
        setAddedPackageList={setAddedPackageList}
        handleClose={() => setOpenPackageOptions(false)} />
      <DialogTitle id="simple-dialog-title">Добавление списания</DialogTitle>
      <DialogContent>
        <Grid style={{marginBottom: '5px', display: 'flex'}}>
          <Typography variant={'h6'}>Дата: </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="dd.MM.yyyy"
              id="date-picker-inline"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              style={{padding: '0 5px'}}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid className={classes.selectWrap}>
          {
            positions.length ? positions.map((category) => <WriteOffSelect
              handleOpenOptions={handleOpenOptions}
              handleOpenPackageOptions={handleOpenPackageOptions}
              products={products}
              key={category[0].category}
              category={category}
              currentDate={selectedDate}
              addedList={addedList}
              addedIngredientList={addedIngredientList}
              addedPackageList={addedPackageList}
              updateList={setAddedList}
              updateIngredientList={setAddedIngredientList}
              updatePackageList={setAddedPackageList} />) : null
          }
        </Grid>
        <Grid className={classes.addedList}>
          {
            addedList.length ? addedList.map((item) => {
              const fullName = item.options.length ? ` ${item.options[0].name === 'Тонкое' ? 'Т' : ''} ${item.options[0].value}` : ''
              return (
                <Grid key={item.options[0] ? item.product_id + item.options[0].product_option_id : item.product_id} className={classes.listItem}>
                  <Typography variant="body2">{item.name + fullName}</Typography>
                  <input style={{width: '25px', margin: '0px 5px'}} value={item.quantity} onChange={(e) => handleChangeQuantity(e.target.value, item)}/> шт.
                  <FormControlLabel
                    style={{margin: '0'}}
                    label="упаковка"
                    control={<Checkbox style={{color: 'white', padding: '0'}} checked={item.is_with_packaging} onChange={() => handleChange(item)} value={item.is_with_packaging}/>}
                  />
                  <IconButton onClick={() => deleteItemList(item)} size="small" className={classes.closeButton}>
                    <CloseIcon size="small"/>
                  </IconButton>
                </Grid>
              )
            }) : null
          }
        </Grid>
        <Grid className={classes.addedList}>
          {
            addedIngredientList.length ? addedIngredientList.map((item) => {
              return (
                <Grid key={item.ingredient_id} className={classes.listItem}>
                  <Typography variant="body2">{item.name}</Typography>
                  <input style={{width: '40px', margin: '0px 5px'}} value={item.weight} onChange={(e) => handleChangeWeight(e.target.value, item)} /> г.
                  <IconButton onClick={() => deleteItemIngredientList(item)} size="small" className={classes.closeButton}>
                    <CloseIcon size="small" />
                  </IconButton>
                </Grid>
              )
            }) : null
          }
        </Grid>
        <Grid className={classes.addedList}>
          {
            addedPackageList.length ? addedPackageList.map((item) => {
              const fullName = item.options.length ? `${item.options[0].value}` : ''
              return (
                <Grid key={item.options[0] ? item.package_id + item.options[0].package_option_id : item.package_id} className={classes.listItem}>
                  <Typography variant="body2">{item.name + fullName}</Typography>
                  <input style={{width: '25px', margin: '0px 5px'}} value={item.quantity} onChange={(e) => handleChangeCount(e.target.value, item)}/> шт.
                  <IconButton onClick={() => deleteItemPackageList(item)} size="small" className={classes.closeButton}>
                    <CloseIcon size="small"/>
                  </IconButton>
                </Grid>
              )
            }) : null
          }
        </Grid>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>Закрыть</Button>
          <Button color="primary" onClick={sendItems} variant="contained">Добавить</Button>
        </DialogActions>
      </DialogContent>
      <Snackbar
        open={!!success}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="success">Данные успешно отправлены</Alert>
      </Snackbar>
      <Snackbar
        open={!!error}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="error">Произошла ошибка, попробуйте позже</Alert>
      </Snackbar>
    </Dialog>
  )
}

export default withStyles(styles)(WriteOffWindow)

