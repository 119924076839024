/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import DateSchedule from '../../../../components/DateSchedule';
import currency from 'util/currency';
import { setCoupons } from '../../../../redux/actions/adminActions';
import { connect } from 'react-redux';
import { useHttp } from 'hooks/httpHook';
import PieDiagram from './PieDiagram';
import CouponQuantityDiagram from './CouponQuantityDiagram';
import CircularProgress from '@material-ui/core/CircularProgress';
import CouponSelect from 'components/CouponSelect';
//MUI
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import withStyles from '@material-ui/core/styles/withStyles';
import Button from '@material-ui/core/Button';

const styles = theme => ({
  couponAnalytics: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  couponsDiagram: {
    height: '400px',
    width: '49.5%',
    background: theme.palette.colors.lightgrey
  },
  quantityDiagram: {
    height: '500px',
    width: '100%',
    background: theme.palette.colors.lightgrey
  },
  couponsDate: {
    width: '49.5%',
    marginBottom: '1rem',
    height: '400px',
    background: theme.palette.colors.lightgrey
  },
  completeWrapper: {
    position: 'relative',
    display: 'inline-block'
  },
  buttonProgress: {
    zIndex: '1000',
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  couponsStats: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  pie: {
    width: '49%'
  },
  couponStat: {
    width: '23%',
    marginBottom: '1%',
    height: '100px',
    padding: theme.spacing(2)
  },
  couponSelect: {
    display: 'flex',
  },
  formControl: {
    minWidth: '200px'
  },
  piesTitle: {
    paddingBottom: '10px'
  }
})

const CouponsTab = ({ classes, admin }) => {
  const { request, loading } = useHttp();
  const [couponData, setCouponData] = useState({});
  const [couponGraphic, setCouponGraphic] = useState([]);
  const [dateState, setDateState] = useState({});
  const [coupon, setCoupon] = useState({});

  useEffect(() => {
    if (coupon.code) {
      setDateState({
        date_start: coupon.date_start,
        date_end: new Date(),
      })
    }
  }, [coupon]);


  const sendData = async (e) => {
    e.preventDefault();

    if (!coupon) {
      return;
    }

    const data = {
      coupon: coupon.code,
      date_start: dateState.date_start,
      date_end: dateState.date_end
    }

    let result = await request('/admin/analytics/coupons', 'post', data);

    if (result) {
      setCouponData(result);
    }

    let couponResult = await request('/admin/analytics/coupons/days', 'post', data);

    if (couponResult) {
      setCouponGraphic(couponResult);
    }
  }

  const firstPieData = {
    titles: ['Новые', 'Старые'],
    data: [
      {
        itemStyle: {
          color: '#644593'
        },
        value: couponData.old_users || 0,
        name: 'Старые',
      },
      {
        itemStyle: {
          color: '#8e6dc2'
        },
        value: couponData.new_users || 0,
        name: 'Новые'
      }
    ]
  }
  const secondPieData = {
    titles: ['Вернулись', 'Не вернулись'],
    data: [
      {
        itemStyle: {
          color: '#ff9e43'
        },
        name: 'Вернулись',
        value: couponData.repeat_users || 0
      },
      {
        itemStyle: {
          color: '#b35600'
        },
        name: 'Не вернулись',
        value: couponData.new_users - couponData.repeat_users || 0
      }
    ]
  }
  return (
    <Grid className={classes.couponAnalytics}>
      <Card className={classes.couponsDate}>
        <CardHeader title="Данные по купонам" />
        <Grid style={{ padding: '0 15px 15px 15px' }}>
          <form onSubmit={sendData}>
            <Grid className={classes.couponSelect}>
              <CouponSelect handleChange={setCoupon} />
            </Grid>
            <DateSchedule dateState={dateState} setDateState={setDateState} />
            <Grid className={classes.completeWrapper}>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
              <Button color="primary" type="submit" disabled={loading} variant="contained">Применить</Button>
            </Grid>
          </form>
        </Grid>
      </Card>
      <Card className={classes.couponsDiagram}>
        <CardContent>
          <Grid className={classes.piesTitle}>
            <Typography>
              Выручка по купону: {currency(couponData.total || 0)}
            </Typography>
            <Typography>
              Использовано раз: {couponData.used || 0}
            </Typography>
          </Grid>
          <Grid className={classes.couponsStats}>
            <Grid className={classes.pie}>
              <PieDiagram data={firstPieData} />
            </Grid>
            <Grid className={classes.pie}>
              <PieDiagram data={secondPieData} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card className={classes.quantityDiagram}>
        <CouponQuantityDiagram data={couponGraphic} />
      </Card>
    </Grid>
  )
}
const mapStateToProps = state => ({
  admin: state.admin
})
export default connect(mapStateToProps, { setCoupons })(withStyles(styles)(CouponsTab))
