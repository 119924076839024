import {
  GET_CATEGORIES,
  LOADING_CATEGORIES,
  LOADING_ORDERS,
  GET_ORDERS,
  CHANGE_ORDERS,
  GET_PROGRESS_ORDERS,
  GET_READY_ORDERS,
  SWITCH_OFF_SOUND,
  GET_HOLDING_ORDERS,
  START_ORDER,
  START_DELIVERY_ORDER,
  SET_ORDER_TIME,
  START_SERVICE_ORDER
} from '../types';
import axios from 'axios';

export const getCategories = () => (dispatch) => {
  dispatch({ type: LOADING_CATEGORIES })
  axios.get('/categories')
    .then(res => {
      dispatch({
        type: GET_CATEGORIES,
        payload: res.data.categories,
        orderId: res.data.order,
        checkId: res.data.check
      });
    })
}

export const getOrders = (stageNumber) => async (dispatch) => {
  dispatch({ type: LOADING_ORDERS });
  await axios({
    method: 'get',
    url: `/kitchen/station/${stageNumber}`,
  })
    .then(res => {
      dispatch({
        type: GET_ORDERS,
        payload: res.data
      })
    })
    .catch(err => console.log(err.response.data));
}
export const changeOrders = (orders) => dispatch => {
  dispatch({
    type: CHANGE_ORDERS,
    payload: orders
  })
}

export const startOrder = () => dispatch => {
  dispatch({
    type: START_ORDER,
  })
}

export const startDeliveryOrder = () => dispatch => {
  dispatch({
    type: START_DELIVERY_ORDER
  })
}

export const setProgressOrders = (orders) => dispatch => {
  dispatch({
    type: GET_PROGRESS_ORDERS,
    payload: orders
  })
}
export const setReadyOrders = (orders) => dispatch => {
  dispatch({
    type: GET_READY_ORDERS,
    payload: orders
  })
}
export const setHoldingOrders = (orders) => dispatch => {
  dispatch({
    type: GET_HOLDING_ORDERS,
    payload: orders
  })
}

export const startServiceOrder = (serviceId) => {
  return {
    type: START_SERVICE_ORDER,
    payload: serviceId
  }
}
export const setOrderDate = (payload) => dispatch => {
  dispatch({
    type: SET_ORDER_TIME,
    payload
  })
}
export const switchOffSound = () => dispatch => {
  dispatch({
    type: SWITCH_OFF_SOUND
  })
}