import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  tableCell: {
    padding: '35px 0'
  }
});

const UserTableSkeleton = (props) => {
  const classes = useStyles(props);
  const content = Array.from({length: 5}).map((item, index) => (
    <TableRow key={index}>
      <TableCell className={classes.tableCell} component="th" scope="row"> </TableCell>
      <TableCell className={classes.tableCell} align="right"> </TableCell>
      <TableCell className={classes.tableCell} align="right"> </TableCell>
      <TableCell className={classes.tableCell} align="right"> </TableCell>
      <TableCell className={classes.tableCell} align="right"> </TableCell>
      <TableCell className={classes.tableCell} align="right"> 
      </TableCell>
    </TableRow>
  ));
  return content;
}
export default UserTableSkeleton;