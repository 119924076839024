import React from 'react'
import soundFile from '../../img/sounds/notification_sound.mp3';
import withStyles from '@material-ui/core/styles/withStyles';
const styles = theme => ({
  audio: {
    display: 'none'
  }
})
const SoundEffect = (props) => {
  const { stage, classes } = props;
  let content = null;
  const audio = <audio className={classes.audio}
    src={soundFile} autoPlay
  />
  if (stage === 'one') {
    content = audio;
  }
  return (
    <>
      {content}
    </>
  )
}

export default withStyles(styles)(SoundEffect)
