import {
  GET_USERS,
  GET_COUPONS,
  GET_ORDERS_BASE,
  GET_CUSTOMERS,
} from "../types";

const initialState = {
  users: [],
  coupons: [],
  ordersBase: [],
  removeUser: {},
  customersBase: [
    {
      all                : [],
      better_month       : [],
      in_month           : [],
      one_all            : [],
      one_better_month   : [],
      one_in_month       : [],
      medium_all         : [],
      medium_better_month: [],
      medium_in_month    : [],
      max_all            : [],
      max_better_month   : [],
      max_in_month       : [],
    },
  ],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ORDERS_BASE:
      return {
        ...state,
        ordersBase: payload,
      };
    case GET_CUSTOMERS:
      return {
        ...state,
        customersBase: payload,
      };
    case GET_COUPONS:
      return {
        ...state,
        coupons: payload,
      };
    case GET_USERS:
      return {
        ...state,
        users: payload,
      };
    default:
      return state;
  }
}
