import React from 'react'
import InfoIcon from '@material-ui/icons/Info';
import HintIcon from 'components/HintIcon';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { pink } from '@material-ui/core/colors';
const styles = theme => ({
  hintBold: {
    fontWeight: 'bold',
    fontSize: '1.1rem',
  },
});

const OrderDetailsIcon = ({ order, classes, deliveryTime }) => {
  const { is_paid, cash, first_name, telephone, pay_method, comment, is_site, site_invoice_no, time } = order;
  const orderTime = deliveryTime(time);
  let normalTime = time ? orderTime : 'отсутствует';
  const hintContent =
    <Grid>
      <p>
        {
          is_site ? `Номер заказа на сайте: ${site_invoice_no}` : ''
        }
      </p>
      {
        cash ? <p>Сдача с {cash}р.</p> : null
      }
      <p><span className={classes.hintBold}>Имя клиента:</span> {first_name || 'не указано'}</p>
      <p><span className={classes.hintBold}>Номер телефона:</span> {telephone || 'не указан'}</p>
      <p><span className={classes.hintBold}>Способ оплаты:</span> {pay_method === 'cod' ? 'наличные' : 'карта'}</p>
      <p><span className={classes.hintBold}>Статус оплаты:</span> {is_paid ? 'оплачен' : 'не оплачен'}</p>
      <p><span className={classes.hintBold}>Комментарий:</span> {comment || 'отсутствует'}</p>
      <p><span className={classes.hintBold}>Ко времени:</span> <span>{normalTime}</span></p>
    </Grid>
  return (
    <HintIcon config={{ placement: "top", text: hintContent, opened: false }}>
      <InfoIcon style={{ color: pink[700] }} />
    </HintIcon>
  )
}

export default withStyles(styles)(OrderDetailsIcon)
