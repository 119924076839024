import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles';
//MUI
import Card from '@material-ui/core/Card';

const styles = theme => {
  let fullHeight = document.documentElement.clientHeight;
  let height = fullHeight * 0.45 + 'px';
  return ({
    orderWrap: {
      position: 'relative',
      textAlign: 'start',
      margin: '1%',
      width: '23%',
      height,
      padding: '10px',
      cursor: 'pointer',
      transition: '0.3s',
      background: 'white'
    },
    sizeWrap: {
      width: '60%',
      display: 'flex',
      justifyContent: 'space-between'
    }, 
  })
}
const OrderSkeleton = (props) => {
  const { classes } = props;
  const content = Array.from({ length: 5 }).map((item, index) => (
    <Card key={index} className={classes.orderWrap}>
    </Card>
  ));
  return content;
}

export default withStyles(styles)(OrderSkeleton)
