import React, { useEffect, useState } from 'react';
import AddressAutoComplete from 'components/AddressAutoComplete'
import { useFormik } from 'formik';
import validationSchema from './schema';
import { useHttp } from 'hooks/httpHook';
//MUI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  homeData: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  },
  homeDataInput: {
    flexBasis: '45%'
  },
})

const BlockPhoneWindow = ({ classes, openAdd, setOpenAdd, getBlackList }) => {
  const [townAddress, setTownAddress] = useState('');
  const [streetAddress, setStreetAddress] = useState('');
  const { request, loading, success, error, clearError } = useHttp();

  const formik = useFormik({
    initialValues: {
      address: '',
      porch: '',
      floor: '',
      flat: '',
      reason: ''
    },
    validationSchema,
    onSubmit: async values => {
      await request('/blacklist/address', 'post', { ...values, address: streetAddress });
    }
  })

  useEffect(() => {
    if (success) {
      getBlackList();
      setTimeout(() => {
        setOpenAdd(false)
      }, 1000)
    }
  }, [success])

  return (
    <Dialog
      open={openAdd}
      onClose={() => setOpenAdd(false)}
    >
      <DialogTitle>Добавить номер в черный список</DialogTitle>
      <form style={{ width: '600px' }} onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid style={{ display: 'flex', justifyContent: 'space-between' }}>
            <AddressAutoComplete
              streetAddress={streetAddress}
              setStreetAddress={setStreetAddress}
              townAddress={townAddress}
              setTownAddress={setTownAddress}
            />
          </Grid>
          <Grid className={classes.homeData}>
            <TextField
              defaultValue={formik.values.porch}
              label="Подъезд"
              name="porch"
              className={classes.homeDataInput}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.porch && formik.errors.porch ? true : false}
              helperText={formik.touched.porch && formik.errors.porch ? formik.errors.porch : null}
            />
            <TextField
              defaultValue={formik.values.floor}
              label="Этаж"
              name="floor"
              className={classes.homeDataInput}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.floor && formik.errors.floor ? true : false}
              helperText={formik.touched.floor && formik.errors.floor ? formik.errors.floor : null}
            />
            <TextField
              defaultValue={formik.values.flat}
              label="Квартира"
              name="flat"
              className={classes.homeDataInput}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.flat && formik.errors.flat ? true : false}
              helperText={formik.touched.flat && formik.errors.flat ? formik.errors.flat : null}
            />
            <TextField
              label="Введите причину"
              className={classes.homeDataInput}
              name="reason"
              defaultValue={formik.values.reason}
              onChange={formik.handleChange}
              type="text"
              fullWidth
              error={formik.touched.reason && formik.errors.reason ? true : false}
              helperText={formik.touched.reason && formik.errors.reason ? formik.errors.reason : null}
              onBlur={formik.handleBlur}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAdd(false)} color="primary">
            Закрыть
          </Button>
          <Button disabled={loading} variant="contained" type="submit" color="primary">
            Добавить
          </Button>
        </DialogActions>
      </form>
      <Snackbar
        open={!!error}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          severity="error">Произошла ошибка, попробуйте позже</Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={2500}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert
          variant="filled"
          severity="success">Номер успешно изменен</Alert>
      </Snackbar>
    </Dialog>
  )
}

export default withStyles(styles)(BlockPhoneWindow)
