import React, { useEffect } from 'react';
import ReusableTabs from 'components/ReusedTabs';
import OrdersTab from './OrdersTab';
import CouponsTab from './CouponsTab';
import IngredientsTab from './IngredientsTab';
//MUI  
import Card from '@material-ui/core/Card';
const Analytics = (props) => {
  const { classes } = props;

  useEffect(() => {
    document.title = 'Аналитика'
  }, [])

  const tabsInfo = [
    {
      component: <OrdersTab />,
      title: "Заказы"
    },
    {
      component: <CouponsTab />,
      title: "Купоны"
    },
    {
      component: <IngredientsTab />,
      title: "Ингредиенты"
    },
  ]

  return (
    <Card>
      <ReusableTabs tabsContent={tabsInfo} />
    </Card>
  )
}

export default Analytics
