/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import DateSchedule from '../../../components/DateSchedule';
import BaseTable from './BaseTable';
import { connect } from 'react-redux';
import { getOrdersBase } from '../../../redux/actions/adminActions';
//MUI
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

const DataPage = ({ admin: { ordersBase }, getOrdersBase }) => {
  const [dateState, setDateState] = useState({});
  useEffect(() => {
    document.title = 'База заказов'
    getOrdersBase(dateState)
  }, [dateState])

  return (
    <>
      <Card>
        <CardContent>
          <CardHeader title="База заказов" />
          <DateSchedule dateState={dateState} setDateState={setDateState} />
        </CardContent>
      </Card>
      <BaseTable getOrdersBase={getOrdersBase} dateState={dateState} ordersBase={ordersBase} />
    </>
  )
}
const mapStateToProps = state => ({
  admin: state.admin
})

export default connect(mapStateToProps, { getOrdersBase })(DataPage)
