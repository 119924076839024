/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import ReactEcharts from 'echarts-for-react';
import currency from 'util/currency';
import echarts from 'echarts';
//MUI
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

import withStyles from '@material-ui/core/styles/withStyles';

const styles = theme => ({
  pieContent: {
    width: '100%'
  },
  piesWrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
})

const IngredientPie = ({ data, hideFilter = false }) => {
  const [priceSort, setPriceSort] = useState(false);
  const [pieData, setPieData] = useState([]);

  const changePriceSort = (e) => {
    setPriceSort(prev => !prev);
  }


  //логика исключателей
  useEffect(() => {
    if (data.length) {
      let dataArr = data;
      if (!priceSort) {
        dataArr = dataArr.map((item) => {
          return {
            ...item,
            value: +item.price
          }
        })
      } else {
        dataArr = dataArr.map((item) => {
          return {
            ...item,
            value: +item.weight
          }
        });
      }
      dataArr = dataArr.sort((a, b) => Number(a.value) > Number(b.value) ? 1 : -1).reverse();
      setPieData(dataArr);
    }
  }, [data, priceSort])

  const option = {
    legend: {
      left: 10,
      top: 'bottom',
      data: pieData.map((item) => item.name)
    },
    tooltip: {
      trigger: 'axis',
      // trigger: 'item',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        if (params.length) {
          let param = params[0];
          if (param.data.quantity) {
            return `
            ${param.name} 
            <br/> 
            Количество: ${param.data.quantity}
            <br/>
            Цена: ${currency(param.value)}`
          }
          return `
          ${param.name} 
          <br/> 
          ${priceSort ?
              `
            Вес: ${param.value > 1000 ? (param.value / 1000).toFixed(2) : param.value} ${param.value > 1000 ? 'кг' : 'г'}. <br/>
          Цена: ${currency(param.data.price)}
          ` :
              `
          Вес: ${param.data.weight > 1000 ? (param.data.weight / 1000).toFixed(2) : param.data.weight} ${param.data.weight > 1000 ? 'кг' : 'г'}. <br/>
          Цена: ${currency(param.value)} 
          `
            }
          `
        }
      },
      backgroundColor: '#ff9e43',
      borderColor: 'white',
    },

    xAxis: {
      data: pieData.map((item) => item.name),
      axisLabel: {
        interval: 0,
        rotate: 90,
        inside: true,
        textStyle: {
          color: 'black'
        }
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
      z: 10
    },
    yAxis: {
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        textStyle: {
          color: '#999'
        }
      }
    },
    dataZoom: [
      {
        type: 'inside'
      }
    ],
    series: [
      {
        type: 'bar',
        itemStyle: {
          color: "#9264d9"
        },
        emphasis: {
          itemStyle: {
            color: new echarts.graphic.LinearGradient(
              0, 0, 0, 1,
              [
                { offset: 0, color: '#bd9de0' },
                { offset: 0.5, color: '#af93cf' },
                { offset: 1, color: '#cfbce6' }
              ]
            )
          }
        },
        data: pieData
      }
    ]
  }

  return (
    <>
      {
        !hideFilter ?
          (
            <Grid style={{ display: 'flex' }}>
              <Grid component="label" container alignItems="center">
                <Grid item>По цене</Grid>
                <Grid item>
                  <Switch checked={priceSort} onChange={changePriceSort} />
                </Grid>
                <Grid item>По весу</Grid>
              </Grid>
            </Grid>
          ) : (
            <Grid component="label" container alignItems="center">
              <Grid item>По цене</Grid>
              <Grid item>
                <Switch checked={priceSort} onChange={() => setPriceSort((prev) => !prev)} name="checkedC" />
              </Grid>
              <Grid item>По весу</Grid>
            </Grid>
          )
      }
      <ReactEcharts
        style={{ height: '450px' }}
        option={option}
        opts={{ renderer: 'svg' }}
      />
    </>
  )
}

export default withStyles(styles)(IngredientPie);
